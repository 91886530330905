import React, { useEffect, useState,useRef  } from 'react';
import intl from 'react-intl-universal';

import styles from './Sorter.less';

const ArrowSorterItem = ({
  title,
  valueUp,
  valueDown,
  currentValue,
  onChange,
  reverse
}) => {
  const activeUp = currentValue === valueUp;
  const activeDown = currentValue === valueDown;
  const active = activeUp || activeDown;
  const handleClick = () => {
    if (reverse) {
      const value = activeDown ? valueUp : valueDown;
      onChange(value);
      return;
    }
    window.dtag.set({
      event:'click',
      module:'tmall',
      action:'tmall_figure_search',
      custom_data:{
        'click_type':'price'
      }
    })
    const value = activeUp ? valueDown : valueUp;
    onChange(value);
  };
  return (
    <div
      onClick={handleClick}
      className={`${styles.sorterItem} ${active ? styles.active : ''}`}
      role="button"
      tabIndex="-1"
    >
      <span className={styles.sorterName}>{title}</span>
      <div className={styles.arrowContainer}>
        <span
          className={`${styles.arrowUp} ${activeUp ? styles.activeItem : ''}`}
        />
        <span
          className={`${styles.arrowDown} ${
            activeDown ? styles.activeItem : ''
          }`}
        />
      </div>
    </div>
  );
};

const SorterItem = ({ title, value, currentValue, onChange, sortStatus }) => {
  const active = value === currentValue;
  const handleClick = () => {
    if (value === currentValue) {
      return;
    }
    window.dtag.set({
      event:'click',
      module:'tmall',
      action:'tmall_figure_search',
      custom_data:{
        'click_type': value
      }
    })
    onChange(value);
  };
  return (
    <div
      onClick={(sortStatus && ['SHIP48H','RATING'].includes(value)) ? '' : handleClick}
      className={`${styles.sorterItem} ${active ? styles.active : ''} ${(sortStatus && ['SHIP48H','RATING'].includes(value)) ? styles.disabled : ''}`}
      role="button"
      tabIndex="-1"
    >
      <span className={styles.sorterName}>{title}</span>
    </div>
  );
};

const sorters = [
  {
    title: intl.get('bargain.sorter.default'),
    type: 'single',
    value: 'DEFAULT'
  },
  {
    title: intl.get('bargain.sorter.new'),
    type: 'single',
    value: 'NEWEST'
  },
  {
    title: intl.get('bargain.sorter.price'),
    type: 'arrow',
    valueUp: '+price',
    valueDown: '-price'
  },
];

const inputSorters = [
  ...sorters,
  {
    title: 'Ships within 48 hours',
    type: 'single',
    value: 'SHIP48H'
  },
  {
    title: 'Rating',
    type: 'single',
    value: 'RATING'
  },
];


const Sorter = ({ currentSorter, onChange, currentInput }) => {

  const [sortStatus,setSortStatus] = useState(true)
  const sort = useRef(sorters)

  useEffect(()=>{
    if(!currentInput){
      setSortStatus(true)
      sort.current = sorters
      if(currentSorter === 'RATING' || currentSorter === 'SHIP48H'){
        onChange('DEFAULT')
      }
    }else{
      setSortStatus(false)
      sort.current = inputSorters
    }
  },[currentSorter,currentInput])

  return (
    <div className={styles.sorterContainer}>
      <span className={styles.sorterTitle}>{`${intl.get('supplier_optimizer.sort_by')} :`}</span>
      <div className={styles.sorterInnerContainer}>
        {sort.current.map(item => {
          const Component =
            item.type !== 'arrow' ? SorterItem : ArrowSorterItem;
          return (
            <Component
              key={item.title}
              onChange={onChange}
              title={item.title}
              currentValue={currentSorter}
              value={item.value}
              valueUp={item.valueUp}
              valueDown={item.valueDown}
              reverse={item.reverse}
              sortStatus={sortStatus}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Sorter;
