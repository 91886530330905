import React, { useState, useEffect } from 'react';

import { Get } from 'utils/request';
import { getLanguage } from 'utils/utils';
import { dsersBFFApiHost } from 'common/sysconfig';

function useCategory() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // 只是优化策略，避免闪烁
    try {
      const cacheData = JSON.parse(
        sessionStorage.getItem('category_bargain_all_tmall')
      );
      if (cacheData) {
        setData(cacheData);
      }
    } catch (error) {
      console.warn('get cache data error', error);
    }

    async function fetchData() {
      setLoading(true);
      try {
        const res = await Get(
          `${dsersBFFApiHost}/dsers-product-bff/product-pool/product/category_list`,{
            isCustomApi:true
          }
        );

        if (res.category.length) {
          const dataArray = res.category || [];
          dataArray.forEach(item=>{
            item.code = item.id
            item['name_en'] = item.nameEn
            item['name_fr'] = item.nameEn
            item['name_pt'] = item.nameEn
            if(item.children.length){
              item.children.forEach(child=>{
                child.children = null
                child.code = child.id
                child['name_en'] = child.nameEn
                child['name_fr'] = child.nameEn
                child['name_pt'] = child.nameEn
              })
            }
          })
          setData(dataArray);
          sessionStorage.setItem(
            'category_bargain_all_tmall',
            JSON.stringify(dataArray)
          );
        }
      } catch (error) {
        console.log('fetch error:', error);
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  return [data, loading];
}

export default useCategory;
