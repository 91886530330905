import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
  useRef,
  createContext
} from 'react';
import {
  Pagination,
  Spin,
  Empty,
  Affix,
  Alert,
  Modal,
  Steps,
  Button,
  Tabs
} from 'antd';
import { routerRedux } from 'dva/router';
import { connect } from 'dva';
import { stringify } from 'qs';
import intl from 'react-intl-universal';
import { emitCustomEvent } from 'utils/dsersCustomEvent';

import { dispatchEventForGuide } from '../BeginnerGuide/util';
// import TagFilter from './component/TagFilter';
import Goods from './component/Goods';
import BannerCard from './component/BannerCard';
import BargainModal from './component/BargainModal';
import { Get, Post } from '../../utils/request';
import { pageSizeOptions } from '../../utils/constants';
// import Banner from './component/Banner';
import Banner2 from './component/Banner2';
import guideData from './component/guideData';
// import BannerV2 from 'components/AdBanner/BannerV2';
import VatModal from 'components/VatModal';
import { LinkStoreAction } from 'actions'



import {
  reducer,
  initialState,
  filterReducer,
  initFilterParams
} from './reducer';

import styles from './index.less';
import { getCount } from 'services/order';
import SearchProduct from './component/SearchProduct';
import FilterAndSorter from './component/FilterAndSorter';
import useListExposureCounter from '../../hooks/useListExposureCounter';
import {
  bargainEventBus,
  checkParamsForExtFetch,
  createProductEventParams,
  fetchSearchResultFromExt
  // getBargainedProducts,
  // getProducts,
  // setBargainedProduct
} from './util';
import emptyImg from '../../assets/bargain/empty_img.png';
import { getLanguage, CommonDataCollect } from 'utils/utils';
import ScrollControl from 'components/ScrollControl';
import useActionCount from './useActionCount';
import useCategory from './useCategory';
import useOnlineNotice from 'hooks/useOnlineNotice';
import UploadImageModal from 'routes/SupplierOptimizer/components/UploadImageModal';
import SearchAlert from './component/SearchAlert';
import { render } from 'react-dom';
import { postSearchResultEvent } from 'routes/SupplierOptimizer/util';
import { fetchSearchList } from 'utils/fetchFromExtension';
import { visible } from 'chalk';
import dsersRoboter from '../../assets/guide/dsers.png';
import dsers from '../../assets/guide/dsers.png';
import fireworks84 from '../../assets/guide/84.png';
import fireworks138 from '../../assets/guide/138.png';
import fireworks188 from '../../assets/guide/188.png';
import { style } from '@material-ui/system';
import FindSupplierAd from 'components/SideSupplyChainAd/findSupplierAd/FindSupplierAd';
import CustomizedArea from './component/CustomizedArea/CustomizedArea';
import { FindSupplierUser } from 'utils/bannerWhiteList';
import _ from 'lodash';
import close_2 from 'assets/img/tmallTheme/tmallClose_2.png';
import close_3 from 'assets/img/tmallTheme/tmallClose_3.png';
import close_1 from 'assets/img/tmallTheme/tmallClose_1.png';
import close_5 from 'assets/img/tmallTheme/tmallClose_5.png';
import close_4 from 'assets/img/tmallTheme/tmallClose_4.png';
import tmallCat from 'assets/img/tmallTheme/tmallCat.png';

const { Step } = Steps;

const emptyImage = <img style={{ height: '158px' }} src={emptyImg} />;

const SearchEmptyDescription = ({ title }) => {
  return <span className={styles.searchEmptyDescription}>{title}</span>;
};

const CustomEmpty = ({ keyword }) => {
  return keyword ? (
    <Empty
      className={styles.customEmpty}
      style={{ marginTop: 100 }}
      description={
        <SearchEmptyDescription
          title={intl.get('bargain.search_empty_tip', {
            keyword
          })}
        />
      }
      image={emptyImage}
    />
  ) : (
    <Empty style={{ marginTop: 100 }} />
  );
};
const flatActionParamKey = 'params';
const createFlatActionParams = (actionParams = {}) => {
  const obj = {};
  Object.keys(actionParams).forEach(key => {
    obj[`${flatActionParamKey}.${key}`] = actionParams[key];
  });
  return obj;
};

// 上报商品曝光事件
const sendExposureEvent = (exposureObj, page, pageSize, actionParams) => {
  const flatParams = createFlatActionParams(actionParams);
  exposureObj.rowData.forEach((item, index) => {
    emitCustomEvent({
      event_category: 'product',
      event_action: 'exposure',
      event_label: 'product_pool',
      pos: exposureObj.position + index,
      page,
      page_size: pageSize,
      ...createProductEventParams(item),
      ...flatParams
    });
  });
};

const sendActionCount = (actionObj, params = {}) => {
  emitCustomEvent({
    // 用户动作
    event_category: 'page',
    // 用户动作类型 点击分类 筛选 排序 热词 关键词搜索
    event_action: 'user_action',
    // 事件标签 商品池
    event_label: 'product_pool',
    // 用户操作次数统计 每次进入页面后重置为0
    event_count: actionObj.actionCount,
    action_type: actionObj.actionType,
    ...params,
    type: params.category
  });
};

// 圣诞样式
const christmasDate = new Date(2020, 11, 17);

const imgSearchResultSorters = {
  '+price': list =>
    list.sort((item1, item2) => item1.priceSortValue - item2.priceSortValue),
  '-price': list =>
    list.sort((item1, item2) => item2.priceSortValue - item1.priceSortValue),
  '-orders': list => list.sort((item1, item2) => item2.orders - item1.orders),
  '+orders': list => list.sort((item1, item2) => item1.orders - item2.orders)
};

const imgSearchResultSorter = (list, sort) => {
  const sorter = imgSearchResultSorters[sort];
  if (!sorter) {
    return list;
  }
  return sorter([...list]);
};

function BargainPage({ dispatch, lang, userInfo, isoberlouser, isSaintsTheme }) {
  const [state, selfDispatch] = useReducer(reducer, initialState);
  // 筛选、排序控制项
  const [filterParams, filterDispatch] = useReducer(
    filterReducer,
    initFilterParams
  );
  // 记录用户动作 用于用户动作上报
  const [latestAction, setLatestAction] = useActionCount();
  // 一键mapping广告banner
  const [bannerLoading, setBannerLoading] = useState(true);

  const [bannerData, setBannerData] = useState([]);
  const [bannerList, setBannerList] = useState([]);

  const [showSearchEmptyTip, setShowSearchEmptyTip] = useState(false);
  const [emptyTipType, setEmptyTipType] = useState(1);
  const [prevSearchCondition, setPrevSearchCondition] = useState({});

  const [initFinished, setInitFinished] = useState(false);
  // 分页ref 用于曝光点击事件上报
  const paginationRef = useRef(state.pagination);
  // 用户动作ref 用于用户动作上报
  const actionParamsRef = useRef({});
  const isTaobaoRef = useRef(false);
  // 上传图片弹框
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  // 是否圣诞主题
  const chrismasTheme = false;
  // 图搜结果集
  const [imgSearchData, setImgSearchData] = useState([]);
  // 搜索提示
  const [searchTip, setSearchTip] = useState(false);
  // 数据可能需要一些额外的处理
  const [guideNum, setGuideNum] = useState(0);
  // 引导视频计数
  const [guideStorage, setGuideStorage] = useState('import');
  const products = useMemo(() => {
    return state.data;
  }, [state.data]);

  const [showVat, setShowVat] = useState(false);

  // 新人引导页面
  const [guideMadalStatus, setGuideMadalStatus] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [orderModal, setOrderModal] = useState(false);

  const filterNotChange = useMemo(() => {
    return JSON.stringify(filterParams) === JSON.stringify(initFilterParams);
  }, [filterParams]);

  // 商品分类数据
  const [category] = useCategory();

  const getBannerList = async () => {
    const stores = userInfo.stores || [];
    const storeIds = stores.map(item => {
      return item?.id;
    });
    const params = {
      data: {
        store_ids: storeIds
      }
    };

    const res = await Get('/prod/propool/ad/list', params);
    if (res && res.code === 2000) {
      res.data.forEach(item => {
        item.isBanner = true;
      });
      setBannerList(res.data);
    }
  };

  useEffect(() => {
    getBannerList();
  }, []);

  useEffect(() => {
    window.dataLayer.push({
      event: 'productBargainEvent',
      type: 'pageLoaded'
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem('un_scv_user_guide')) {
      setGuideStorage(localStorage.getItem('un_scv_user_guide'));
    }
  });

  // 搜索或筛选排序时请求数据
  useEffect(() => {
    // console.log(latestAction);
    // 当前数据源不为dsers商品池 则不触发搜索
    if (state.dataSource !== 'dsers') {
      return () => {};
    }

    async function fetchProductData() {
      selfDispatch({ type: 'loading', payload: true });
      let partitions = null;
      if (filterParams.partition) {
        partitions = filterParams.partition?.map(item => {
          return item.code;
        });
        partitions = partitions?.toString();
      } else {
        partitions = null;
      }
      if (partitions?.includes('Customized Package')) {
        selfDispatch({ type: 'loading', payload: false });
        return;
      }
      const params = {
        current: state.pagination.current,
        page_size: state.pagination.pageSize,
        ...filterParams,
        partition: partitions,
        lang: getLanguage().replace('-', '_'),
        is_taobao: state.pagination.current === 1 ? false : isTaobaoRef.current
      };
      params.category = params.category[params.category.length - 1]?.code || '';
      // 点击曝光上报查询参数
      actionParamsRef.current = params;
      // action上报是否筛选到内容
      let fetchSuccess = false;

      let alertType = false;

      if (window.DS_BEGINNER_GUIDE_ACTIVE) {
        Object.assign(params, { is_tutorial: 1 });
      }

      if (state.search) {
        Object.assign(params, { q: state.search });
      }

      try {
        const res = await Get(
          `/onedata_propool/product_pool/search?${stringify(params)}`
        );

        if (res && res.code === 2000) {
          if (res.data) {
            if (state.search) {
              window.dtag.set({
                event: 'search',
                module: 'AliExpress_find_search',
                action: 'AliExpress_find_search',
                custom_data:{
                  search:state.search
                }
              })
           }  
            const contentLength = res.data.content?.length || 0;
            let list = res.data.content || [];
            let total = res.data.total || 0;
            isTaobaoRef.current = res.data.is_taobao;
            if (contentLength) {
              fetchSuccess = true;
              setPrevSearchCondition(params)
              setShowSearchEmptyTip(false)
            }else{
              setShowSearchEmptyTip(true)
              getPrevDataList(prevSearchCondition)
              if(params?.q && !params.category && !params?.partition && !params?.shipping_from){
                setEmptyTipType(1)
              }else{
                setEmptyTipType(2)
              }
            }
            // 当用户使用关键词搜索且服务端返回数量小于一页数据 使用插件抓取ae搜索结果
            if (total < 60 && checkParamsForExtFetch(params)) {
              if (!window.dsersExtVersion) {
                alertType = 'installExtension';
                window.dataLayer.push({
                  event: 'bargainEvent',
                  category: 'showInstallExtBanner',
                  payload: window.DSERS_INSTALL_REMAINDER ? 'A' : 'B'
                });
              } else {
                window.dataLayer.push({
                  event: 'bargainEvent',
                  category: 'searchByExt'
                });
                const extRes = await fetchSearchResultFromExt(
                  params,
                  list,
                  total
                );
                list = extRes.list;
                total = extRes.pageCount;
                // console.log(total)
              }
            }
            const idArr = [];
            list.forEach(i => {
              idArr.push(i.id);
            });

            const payload = {
              data: {
                ae_product_ids: idArr
              }
            };
            const response = await Post(
              '/product_pool/import_status/list',
              payload
            );
            if (response && response.code === 2000) {
              const importedArr = response.data;
              list.forEach(i => {
                if (importedArr.indexOf(i.id) >= 0) {
                  i.imported = true;
                }
              });
            }
            selfDispatch({
              type: 'setPaginationData',
              payload: { total }
            });
            selfDispatch({ type: 'setData', payload: list });
          }
        }
      } catch (error) {
        console.log('fetch error:', error);
      }
      setSearchTip(alertType);
      selfDispatch({ type: 'loading', payload: false });
      setInitFinished(true);
      if (latestAction) {
        sendActionCount(latestAction, {
          ...params,
          fetch_success: fetchSuccess
        });
      }
    }

    fetchProductData();
  }, [
    filterParams,
    state.pagination.current,
    state.pagination.pageSize,
    state.search,
    state.dataSource
  ]);

  const getPrevDataList = useCallback(async(params)=>{
    selfDispatch({ type: 'loading', payload: true });
    let partitions = null;
    if (filterParams.partition) {
      partitions = filterParams.partition?.map(item => {
        return item.code;
      });
      partitions = partitions?.toString();
    } else {
      partitions = null;
    }
    if (partitions?.includes('Customized Package')) {
      selfDispatch({ type: 'loading', payload: false });
      return;
    }
   
    // action上报是否筛选到内容
    let fetchSuccess = false;

    let alertType = false;

    

    try {
      const res = await Get(
        `/onedata_propool/product_pool/search?${stringify(params)}`
      );

      if (res && res.code === 2000) {
        if (res.data) {
          const contentLength = res.data.content?.length || 0;
          let list = res.data.content || [];
          let total = res.data.total || 0;
          isTaobaoRef.current = res.data.is_taobao;
          if (contentLength) {
            fetchSuccess = true;
          }
          // 当用户使用关键词搜索且服务端返回数量小于一页数据 使用插件抓取ae搜索结果
          if (total < 60 && checkParamsForExtFetch(params)) {
            if (!window.dsersExtVersion) {
              alertType = 'installExtension';
              window.dataLayer.push({
                event: 'bargainEvent',
                category: 'showInstallExtBanner',
                payload: window.DSERS_INSTALL_REMAINDER ? 'A' : 'B'
              });
            } else {
              window.dataLayer.push({
                event: 'bargainEvent',
                category: 'searchByExt'
              });
              const extRes = await fetchSearchResultFromExt(
                params,
                list,
                total
              );
              list = extRes.list;
              total = extRes.pageCount;
              // console.log(total)
            }
          }
          const idArr = [];
          list.forEach(i => {
            idArr.push(i.id);
          });

          const payload = {
            data: {
              ae_product_ids: idArr
            }
          };
          const response = await Post(
            '/product_pool/import_status/list',
            payload
          );
          if (response && response.code === 2000) {
            const importedArr = response.data;
            list.forEach(i => {
              if (importedArr.indexOf(i.id) >= 0) {
                i.imported = true;
              }
            });
          }
          selfDispatch({
            type: 'setPaginationData',
            payload: { total }
          });
          selfDispatch({ type: 'setData', payload: list });
        }
      }
    } catch (error) {
      console.log('fetch error:', error);
    }
    setSearchTip(alertType);
    selfDispatch({ type: 'loading', payload: false });
    setInitFinished(true);
    if (latestAction) {
      sendActionCount(latestAction, {
        ...params,
        fetch_success: fetchSuccess
      });
    }
  })

  useEffect(() => {
    // 列表更新后，启动新手教程
    if (state.data && state.data.length > 0 && !bannerLoading) {
      dispatchEventForGuide({
        type: 'bargain',
        payload: { category: 'rendered' }
      });
    }
  }, [bannerLoading, state.data, dispatch]);

  // 保存分页当前数据 用于事件上报
  useEffect(() => {
    paginationRef.current = {
      current: state.pagination.current,
      pageSize: state.pagination.pageSize
    };
  }, [state.pagination]);

  useEffect(() => {
    // 用户以关键词搜索或选择分区分类时取消图搜
    if (state.dataSource === 'similar_by_img') {
      selfDispatch({
        type: 'setSource',
        payload: 'dsers'
      });
    }
  }, [
    state.search,
    filterParams.category,
    filterParams.partition,
    state.pagination.current
  ]);

  useEffect(() => {
    async function importedProductHandler(list) {
      const idArr = [];
      list.forEach(i => {
        idArr.push(i.id);
      });
      const payload = {
        data: {
          ae_product_ids: idArr
        }
      };
      const response = await Post('/product_pool/import_status/list', payload);
      if (response && response.code === 2000) {
        const importedArr = response.data;
        list.forEach(i => {
          if (importedArr.indexOf(i.id) >= 0) {
            i.imported = true;
          }
        });
        selfDispatch({ type: 'setData', payload: list });
      }
    }

    // 用户点击排序时 图搜结果进行排序
    if (!imgSearchData.length) {
      return;
    }
    if (state.dataSource === 'similar_by_img') {
      const { sort } = filterParams;
      const list = imgSearchResultSorter(imgSearchData, sort);
      importedProductHandler(list);
    }
  }, [filterParams.sort, imgSearchData]);

  // 上报曝光事件
  const handlerExposureCallback = useCallback(exposureObj => {
    const actionParams = actionParamsRef.current;
    return sendExposureEvent(
      exposureObj,
      paginationRef.current.current,
      paginationRef.current.pageSize,
      actionParams
    );
  }, []);

  // 监听商品曝光
  useListExposureCounter(
    '.bargain-good-stastics',
    state.data,
    handlerExposureCallback,
    1,
    1000,
    0.75
  );

  // 一键mapping广告 暂时下线
  useEffect(() => {
    async function fetchProductData() {
      setBannerLoading(true);
      try {
        const params = {
          data: {
            lang: getLanguage().replace('-', '_')
          }
        };
        const res = await Get('/prod/propool/spread', params);

        if (res && res.code === 2000 && res.data) {
          setBannerData(res.data);
        }
      } catch (error) {
        console.log('fetch error:', error);
      }

      setBannerLoading(false);
    }
    // 一键mapping广告接口404处理 暂时注释这部分代码
    // fetchProductData();
    setBannerLoading(false);
  }, []);

  // 筛选或排序控件变化时记录用户动作并修改分页至第一页
  const handleFilterChange = useCallback(
    key => {
      setLatestAction({
        actionType: key,
        actionTime: Date.now()
      });
      selfDispatch({
        type: 'setPaginationData',
        payload: { current: 1 }
      });
    },
    [setLatestAction]
  );

  // 用户导入成功并选择去import list 查看
  const handleImportOk = useCallback(() => {
    dispatch(routerRedux.push('/storemanage/import'));
  }, [dispatch]);

  // 用户触发搜索框 记录用户动作并设置分页到第一页
  const handleSearch = useCallback(
    (value, isHotWord) => {
      if (value) {
        window.dataLayer.push({
          event: 'productBargainEvent',
          type: 'search',
          payload: value
        });
      }

      setLatestAction({
        actionType: isHotWord ? 'hotWord' : 'search',
        actionTime: Date.now()
      });

      selfDispatch({
        type: 'setPaginationData',
        payload: { current: 1 }
      });

      selfDispatch({
        type: 'setSearch',
        payload: value
      });
    },
    [setLatestAction]
  );

  function onShowSizeChange(page, size) {
    selfDispatch({
      type: 'setPaginationData',
      payload: {
        current: page,
        pageSize: size
      }
    });
  }
  // 分页改变
  function onPaginationChange(page) {
    selfDispatch({
      type: 'setPaginationData',
      payload: {
        current: page
      }
    });
  }
  // 上报用户点击商品
  const handlePostClickEvent = useCallback(params => {
    const pageInfo = paginationRef.current;
    const actionParams = actionParamsRef.current;
    const flatParams = createFlatActionParams(actionParams);
    emitCustomEvent({
      ...params,
      page: pageInfo.current,
      page_size: pageInfo.pageSize,
      ...flatParams
    });
  }, []);

  // 用户图搜时 清空专区、分类、搜索关键字
  const clearPartitionAndSearch = useCallback(() => {
    bargainEventBus.emit('clearSearchInput');
    selfDispatch({
      type: 'setSource',
      // 这个值是过渡值 为了阻止关键词搜索
      payload: 'similar_by_img_loading'
    });
    filterDispatch({
      type: 'setCategory',
      payload: []
    });
    filterDispatch({
      type: 'setPartition',
      payload: ''
    });
  }, []);

  // 图片搜相似
  const onUploadImage = useCallback(
    async file => {
      if (!file) {
        return false;
      }
      setUploadModalVisible(false);
      selfDispatch({ type: 'loading', payload: true });
      clearPartitionAndSearch();
      const data = new FormData();
      data.append('file', file);
      let result;
      try {
        const res = await Post('/ali_similar_products/product/search_by_file', {
          data
        });
        if ([4000, 5000].includes(res.code)) {
          result = 3;
        } else if (res.data?.content?.length) {
          result = 1;
        } else {
          result = 2;
        }
        const list = (res?.data?.content || []).map(item => {
          const priceObj = {
            ...item.price,
            formatted_amount: item.price.format_amount
          };
          return {
            average_star: item.rating,
            orders: item.trade_count,
            // 添加这个自定义专区及供应商以区分商品
            om_label: { partition: 'search_by_img', supplier: 'aliexpress' },
            price: {
              is_activity: true,
              max_activity_amount: priceObj,
              min_activity_amount: priceObj,
              min_amount: priceObj,
              max_amount: priceObj
            },
            priceSortValue: item.price.value,
            id: item.id,
            image: item.image,
            name: item.title
          };
        });

        // 设置来源为图搜
        selfDispatch({
          type: 'setSource',
          payload: 'similar_by_img'
        });
        const msg = res?.msg || '';
        const resData = res?.data || {};
        const imgObj = resData.upload_image || {};
        const resCategory = resData.categories?.[0]?.name;
        // 上报图搜结果
        postSearchResultEvent(
          result,
          msg,
          '',
          imgObj.url,
          imgObj.code,
          'dsers_page',
          'bargain',
          resCategory,
          'bargain_search_by_img'
        );

        if (list.length) {
          setSearchTip('searchByImgSuccess');
          selfDispatch({
            type: 'setPaginationData',
            payload: { total: list.length }
          });
          setImgSearchData(list);
        } else {
          setSearchTip('searchByImgFailed');
        }
      } catch (error) {
        setSearchTip('searchByImgFailed');
        console.log(error);
      }
      selfDispatch({ type: 'loading', payload: false });
      return false;
    },
    [clearPartitionAndSearch]
  );

  const onCancelUpload = useCallback(() => {
    setUploadModalVisible(false);
  }, []);

  const openUploadModal = useCallback(() => {
    setUploadModalVisible(true);
  }, []);

  // 点击广告卡片的switch按钮
  const handleBannerClick = value => {
    dispatch({
      type: 'global/showOneClickMapping',
      payload: value,
      callback: d => {
        if (d.code == 2000) {
          getBannerList();
        }
      }
    });
  };

  const closeVatModal = () => {
    setShowVat(false);
  };


  const handleOk = () => {
    setOrderModal(false);
    dispatch({
      type: 'guidance/postGuidance',
      payload: {
        data: {
          status: 11000000
        }
      }
    });
    window.EVENT.emit(LinkStoreAction.GET_AUTO_LIST, {})
  }

  useEffect(() => {
    const { status = 0 } = userInfo;

    if (Boolean(status.toString().substring(1, 2) === '0')) {
      // 如果是新人的话 开启新手引导
      if (!isoberlouser) {
        setGuideMadalStatus(true);
        if(localStorage.getItem('un_scv_user_guide')){
          setGuideStorage(localStorage.getItem('un_scv_user_guide'));
        }
      }
    }
  }, []);

  const renderFooter = () => {
    if (guideNum === 0) {
      return (
        <Button
          className={styles.footerRightBtn}
          onClick={() => {
            setGuideNum(guideNum + 1);
          }}
        >
          NEXT
        </Button>
      );
    }

    if (guideNum + 1 < guideData[guideStorage]?.length) {
      return (
        <>
          <Button
            className={styles.footerLeftBtn}
            onClick={() => {
              setGuideNum(guideNum - 1);
            }}
          >
            BACK
          </Button>
          <Button
            className={styles.footerRightBtn}
            onClick={() => {
              setGuideNum(guideNum + 1);
            }}
          >
            NEXT
          </Button>
        </>
      );
    }

    if (
      guideStorage === 'import' &&
      guideNum + 1 === guideData[guideStorage]?.length
    ) {
      return (
        <>
          <Button
            className={styles.footerLeftBtn}
            onClick={() => {
              setGuideNum(guideNum - 1);
            }}
          >
            BACK
          </Button>
          <Button
            className={styles.footerRightBtn}
            onClick={() => {
              setImportModal(true);
              setGuideMadalStatus(false);
            }}
          >
            NEXT
          </Button>
          {/* <Button
            className={styles.footerLeftBtn}
            onClick={() => {
              setGuideMadalStatus(false)
              dispatch({
                type: 'guidance/postGuidance',
                payload: {
                  data: {
                    status: 11000000
                  }
                }
              });
            }}>NO</Button>
          <Button
            className={styles.footerRightBtn}
            onClick={() => {
              localStorage.setItem('un_scv_user_guide', 'order')
              setGuideStorage('order');
              setGuideNum(0)
            }}>OK</Button> */}
        </>
      );
    }

    if (
      guideStorage === 'order' &&
      guideNum + 1 === guideData[guideStorage]?.length
    ) {
      return (
        <>
          <Button
            className={styles.footerLeftBtn}
            onClick={() => {
              setGuideNum(guideNum - 1);
            }}
          >
            BACK
          </Button>
          <Button
            className={styles.footerRightBtn}
            onClick={() => {
              setOrderModal(true);
              setGuideMadalStatus(false);
            }}
          >
            NEXT
          </Button>
        </>
      );
    }
    //   return (
    //     <Button className={styles.footerRightBtn} onClick={() => {
    //       setGuideMadalStatus(false)
    //       //并调用接口传值，告诉后端已经看过了
    //       dispatch({
    //         type: 'guidance/postGuidance',
    //         payload: {
    //           data: {
    //             status: 11000000
    //           }
    //         }
    //       });
    //     }}>OK</Button>
    //   )
    // }

    return (
      <Button
        onClick={() => {
          setGuideMadalStatus(false);
          CommonDataCollect({
            event_type:'guide_modal_error',
            action:'guide_modal_click_error'
          })
        }}
      >
        出错了～
      </Button>
    );
  };

  // 避免 banner 加载造成抖动
  if (bannerLoading) {
    return (
      <Spin size="large" style={{ width: '100%', margin: '180px auto' }} />
    );
  }

  const notNewUser = localStorage.getItem('notNewUser');
  let timeGap;
  let displayGuide;
  if (userInfo) {
    const { status, created_at } = userInfo;
    timeGap = (new Date().getTime() - created_at * 1000) / 86400000;
    if (timeGap <= 1) {
      localStorage.setItem('newCreated', 0);
    } else {
      localStorage.setItem('newCreated', 1);
    }
    displayGuide = !notNewUser && timeGap <= 1;
    if (`${status}`.substring(1, 2) === '1' && displayGuide) {
      // 用户已看完新手引导
      setTimeout(() => {
        const targetEle = document.getElementById('target');
        if (targetEle) {
          targetEle.scrollIntoView({
            block: 'start',
            behavior: 'smooth'
          });
        }
      }, 1000);
      setTimeout(() => {
        localStorage.setItem('notNewUser', true);
      }, 4000);
    }
  }
  const userId = userInfo.user_id;

  const isAdUser = FindSupplierUser.includes(userId);

  return (
    <div
      className={`${styles.bargainPage} ${styles.aeThemeOverride} ${
        chrismasTheme ? 'dsers-chrismas-theme' : ''
      } ${window.TMALL_USER ? styles.bargainPageForTmall : ''}`}
      id="bargain-scroll-top"
    >
      <VatModal showVat={showVat} closeVatModal={closeVatModal} />
      {orderModal && (
        <Modal
          title=""
          visible={orderModal}
          width={560}
          closable={false}
          footer={null}
        >
          <div className={styles.orderModal}>
            Hooray 🎉🎉! The test order is processed to your AliExpress account.
            Next step for you should be to go to AliExpress to make payment. But
            you don’t need to do that for this test order.Basically, after
            making payment on AliExpress, you do not need to do anything, just
            let DSers do the rest. You have finished the tutorial for processing
            orders, enjoy dropshipping with DSers
            <img className={styles.dsers} src={dsers} />
            <Button
              style={{ marginTop: '11px' }}
              onClick={() => {
                handleOk()
              }}
            >
              OK
            </Button>
            <img className={styles.fire2} src={fireworks84} alt="" />
            <img className={styles.fire3} src={fireworks84} alt="" />
            <img className={styles.fire1} src={fireworks138} alt="" />
            <img className={styles.fire4} src={fireworks188} alt="" />
          </div>
        </Modal>
      )}
      {/* {importModal && (
        <Modal
          title=""
          visible={importModal}
          width={480}
          closable={false}
          footer={null}
        >
          <div className={styles.importModal}>
            <div>You have finished the tutorial for importing products. </div>
            Would you like to learn how to process the order?
            <img src={dsers} />
            <div className={styles.footer}>
              <Button
                onClick={() => {
                  setImportModal(false);
                  dispatch({
                    type: 'guidance/postGuidance',
                    payload: {
                      data: {
                        status: 11000000
                      }
                    }
                  });
                }}
                style={{ marginRight: '8px' }}
              >
                No
              </Button>
              <Button
                style={{ marginLeft: '8px' }}
                onClick={() => {
                  setImportModal(false);
                  localStorage.setItem('un_scv_user_guide', 'order');
                  setGuideStorage('order');
                  setGuideMadalStatus(true);
                  setGuideNum(0);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>
      )} */}
      {/* {!isoberlouser
        ? guideMadalStatus && (
            <Modal
              title=""
              visible={guideMadalStatus}
              width={743}
              style={{ position: 'relative' }}
              closable={false}
              footer={null}
            >
              <div className={styles.guideCon}>
                <img
                  src={
                    guideData[guideStorage] &&
                    guideData[guideStorage][guideNum]?.img
                  }
                  alt=""
                />
                <div className={styles.guideBottom}>
                  <img src={dsersRoboter} alt="" />
                  <div>
                    <div className={styles.guideTitle}>
                      {guideData[guideStorage] &&
                        guideData[guideStorage][guideNum]?.title}
                    </div>
                    <div className={styles.bottomText}>
                      {guideData[guideStorage] &&
                        guideData[guideStorage][guideNum]?.text}
                    </div>
                  </div>
                </div>
                {guideData[guideStorage] &&
                  guideData[guideStorage][guideNum]?.nextText && (
                    <div className={styles.guideNext}>
                      {guideData[guideStorage] &&
                        guideData[guideStorage][guideNum]?.nextText}
                    </div>
                  )}
                {renderFooter()}
              </div>
              <Steps
                className={styles.guideSteps}
                direction="vertical"
                current={guideNum}
              >
                {guideData[guideStorage]?.map(item => {
                  return <Step />;
                })}
              </Steps>
            </Modal>
          )
        : null} */}

      <div className={styles.christmasImgBgContainer}>
        <div className={styles.content}>
          <div className={styles.christmasImgBg}>
            <div className={styles.christmasImgLeft} />
            <div className={styles.christmasImgRight} />
          </div>
        </div>
      </div>
      {/* {bannerData && bannerData.length > 0 ? (
        <Banner2 data={bannerData} />
      ) : null} */}
      <div className={styles.outerContent}>
        <Affix
          offsetTop={window.TMALL_USER ? 78 : 0}
          target={() => document.getElementById('BasicLayoutContentById')}
        >
          <div className={styles.whiteBg}>
            <div className={styles.content}>
              <SearchProduct
                dispatch={filterDispatch}
                handleFilterChange={handleFilterChange}
                handleSearch={handleSearch}
                category={category}
                currentCategory={filterParams.category}
                openUploadModal={openUploadModal}
                currentPartion={filterParams.partition}
              />
            </div>
          </div>
        </Affix>
        <FilterAndSorter
          dispatch={filterDispatch}
          onChange={handleFilterChange}
          filterParams={filterParams}
          dataSource={state.dataSource}
          category={category}
          showSearchEmptyTip={showSearchEmptyTip}
          emptyTipType={emptyTipType}
        />
      </div>
      {/* <div className={styles.content}>
        <SearchAlert
          searchTip={searchTip}
          dispatch={selfDispatch}
          // data={state.data}
          filterDispatch={filterDispatch}
          setTip={setSearchTip}
          handleFilterChange={handleFilterChange}
        />
      </div> */}
      {_.findIndex(filterParams.partition, {
        code: 'Customized Package',
        value: 'Customized Package',
        type: 2
      }) === -1 ? (
        <Spin spinning={state.loading} wrapperClassName={styles.spin}>
          <div className={styles.content}>
            {products.length === 0 && initFinished ? (
              <CustomEmpty keyword={state.search} />
            ) : null}
            <div className={styles.goodsCon}>
              {/* {state.pagination.current == 1 &&
              !state.search &&
              state.dataSource !== 'similar_by_img' &&
              filterNotChange &&
              bannerList.map((i, index) => (
                <BannerCard
                  key={index}
                  userId={userId}
                  data={i}
                  handleBannerClick={handleBannerClick}
                />
              ))} */}
              {products.map((i, index) => (
                <Goods
                 params={state}
                  key={i.id}
                  data={i}
                  onImportOk={handleImportOk}
                  // 上报统计事件用
                  className="bargain-good-stastics"
                  index={index}
                  displayGuide={displayGuide}
                  onPostClickEvent={handlePostClickEvent}
                  selectType={'Aliexpress'}
                />
              ))}
            </div>
            <div
              style={{
                textAlign: 'right',
                paddingRight: 24,
                marginTop: 12,
                position: 'relative'
              }}
            >
              <Pagination
                // showSizeChanger
                showQuickJumper
                onShowSizeChange={onShowSizeChange}
                total={state.pagination.total}
                current={state.pagination.current}
                pageSize={state.pagination.pageSize}
                onChange={onPaginationChange}
                pageSizeOptions={pageSizeOptions}
                hideOnSinglePage
              />
            </div>
            <div id="bargain-scroll-botton" />
          </div>
        </Spin>
      ) : (
        <Spin spinning={state.loading}>
          <div className={styles.content}>
            <CustomizedArea></CustomizedArea>
            <div id="bargain-scroll-botton" />
          </div>
        </Spin>
      )}
      <UploadImageModal
        visible={uploadModalVisible}
        onCancel={onCancelUpload}
        onOk={onUploadImage}
        className={styles.searchByImgModal}
        // wrapClassName={}
        selectType={'Aliexpress'}
      />
      <ScrollControl
        topId="bargain-scroll-top"
        bottomId="bargain-scroll-botton"
        className={styles.scrollControl}
        isSaintsTheme={isSaintsTheme}
      />      
      {
        isSaintsTheme ? (
          <>
            <img  id="christmasWazi" src={close_3} alt="" />
            <img  id="christmasToy"  src={close_2} alt="" />
            <img  id="close_1" src={close_1} alt="" />
            <img  id="close_5" src={close_5} alt="" />
            <img  id="tmallCat"  src={tmallCat} alt="" />
          </>
        ) : null
      }

    </div>
  );
}

export default connect(({ global, login, auth }) => ({
  lang: global.lang,
  userInfo: login.userInfo,
  isoberlouser: auth?.isOberloUser,
  isSaintsTheme:global.themeStatus
}))(React.memo(BargainPage));
