import React from 'react';
import intl from 'react-intl-universal';

import img from '../../../assets/img/icon-business-partnership.png';

import styles from './SuccessFeedback.less';

function SuccessFeedback() {
  return (
    <div className={styles.feedback}>
      <img src={img} alt="icon" />
      <h1>{intl.get('bargain.feedback_title')}</h1>
      <p className={styles.highlight}>{intl.get('bargain.counter_limit')}</p>
      <p className={styles.content}>{intl.getHTML('bargain.feedback_con')}</p>
    </div>
  );
}

export default SuccessFeedback;
