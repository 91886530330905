/* eslint @typescript-eslint/camelcase: 0 */

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Button, Modal, Radio, Spin } from 'antd';
import intl from 'react-intl-universal';
import { stringify } from 'qs';
import { connect } from 'dva';

import styles from './Banner2.less';
import Goods from './Goods';
import BannerItem from './BannerItem';
import Mapping from 'components/Mapping';
import copyToClipboard from 'utils/copyToClipboard';

import { Get } from '../../../utils/request';

const formatData = data =>
  data.map(
    ({
      ae_url,
      ae_product_title,
      ae_img,
      dsers_url,
      dsers_product_title,
      dsers_img,
      ...rest
    }) => {
      const aeProduct = {
        url: ae_url,
        title: ae_product_title,
        image: ae_img
      };

      const dsProduct = {
        url: dsers_url,
        title: dsers_product_title,
        image: dsers_img
      };

      return { aeProduct, dsProduct, ...rest };
    }
  );

function Banner2({ data, dispatch }) {
  console.log('--------- Banner2 --------', 2222222);
  const [visible, setVisible] = useState(false);

  const [mappingVisible, setMappingVisible] = useState(false);
  const [mappingData, setMappingData] = useState({});
  const [mappingLoading, setMappingLoading] = useState(false);

  const [selected, setSelected] = useState(undefined);
  const [selectData, setSelectData] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    window.dataLayer.push({
      event: 'productBargainEvent',
      type: 'bargainBannerLoaded'
    });
  }, []);

  useEffect(() => {
    const { hash } = window.location;
    if (!hash) {
      return;
    }

    // eslint-disable-next-line
    const anchor = document.querySelector('#bargain-page-anchor');
    if (anchor) {
      anchor.setAttribute('href', hash);
      console.log('anchor click');
      anchor.click();
    }
  }, [data]);

  const productData = useMemo(() => {
    return (data && formatData(data)) || [];
  }, [data]);

  const handleMappingClose = useCallback(() => {
    setMappingVisible(false);
    // setMappingData({});
  }, []);

  async function handleSwitch(item, index) {
    const rawData = data[index];

    window.dataLayer.push({
      event: 'productBargainEvent',
      type: 'switchButton',
      payload: rawData
    });

    dispatch({
      type: 'global/showOneClickMapping',
      payload: rawData
    });

    // window.open(item.dsProduct.url, '_blank');

    // eslint-disable-next-line
    copyToClipboard(item.dsProduct && item.dsProduct.url);

    // 暂时取消
    // setVisible(true);
    // setModalLoading(true);

    // try {
    //   const res = await Get(
    //     `/prod/propool/spread/shopify?ae_product_id=${item.ae_product_id}`
    //   );

    //   if (res && res.code === 2000) {
    //     setSelectData(res.data || []);
    //     if (res.data && res.data.length === 1) {
    //       setSelected(res.data[0].shopify_product_id);
    //     }
    //   }
    // } catch (error) {
    //   console.log('fetch error:', error);
    // }

    // setModalLoading(false);
  }

  function handleModalCancel() {
    setVisible(false);
    setSelected(undefined);
  }

  function handleSelectedChange(e) {
    setSelected(e.target.value);
  }

  async function handleOpenMapping() {
    const item = selectData.find(i => i.shopify_product_id === selected);

    window.dataLayer.push({
      event: 'productBargainEvent',
      type: 'openMappingButton',
      payload: item
    });

    setMappingLoading(true);

    const params = {
      id: item.shopify_product_id,
      store_id: item.store_id
    };

    try {
      const res = await Get(`/prod/item?${stringify(params)}`);

      if (res && res.code === 2000 && res.data) {
        const { prod, supply } = res.data;
        if (prod) {
          setMappingData({
            data: {
              id: prod.id,
              storeId: item.store_id,
              image: prod.detail.image ? prod.detail.image.src : '',
              title: prod.detail.title,
              prod,
              variant: undefined
            },
            supply
          });

          setVisible(false);
          setMappingVisible(true);
        }
      }
    } catch (error) {
      console.log('fetch error:', error);
    }

    setMappingLoading(false);
    setSelected(undefined);
  }

  return (
    <>
      {productData.map((item, index) => (
        <div
          className={styles.banner}
          key={item.shopify_product_id}
          id={item.dsers_product_id}
        >
          <Goods data={item.aeProduct} simple className={styles.goods} />
          <BannerItem
            style={{ marginRight: 12 }}
            price={item.ae_price}
            feature={item.ae_defect}
          />
          <BannerItem
            highlight
            price={item.dsers_price}
            feature={item.dsers_merit}
            percent={item.percent}
          />
          <Goods data={item.dsProduct} simple className={styles.goods} />
          <div className={styles.mark}>{intl.get('bargain.mass_supply')}</div>
          <div className={styles.arrowMask}>
            <div className={styles.content}>
              <span className={styles.leftCon}>
                SAVE {item.percent}&nbsp;
                <span role="img" aria-label="money icon">
                  💰
                </span>
              </span>
              <span
                onClick={() => handleSwitch(item, index)}
                className={styles.rightCon}
              >
                Check Product
              </span>
            </div>
          </div>
        </div>
      ))}
      <a id="bargain-page-anchor" href="#" style={{ display: 'none' }}>
        {intl.get('bargain.banner2.anchor')}
      </a>

      <Modal
        visible={visible}
        footer={null}
        width={580}
        maskClosable={false}
        keyboard={false}
        onCancel={handleModalCancel}
        bodyStyle={{ padding: '36px 36px' }}
        destroyOnClose
      >
        <h3 className={styles.mTitle}>
          {intl.get('bargain.banner2.thank_you')}
        </h3>
        {!modalLoading && selectData.length === 0 ? (
          <div className={styles.mNotProd}>
            {intl.getHTML('bargain.banner2.delete_product_tip')}
          </div>
        ) : (
          <div className={styles.mSubTitle}>
            {intl.get('bargain.banner2.copied_tip：')}
          </div>
        )}
        <Spin spinning={modalLoading}>
          <div className={styles.supply}>
            <Radio.Group onChange={handleSelectedChange} value={selected}>
              {selectData.map(i => (
                <div
                  style={{ marginBottom: 8 }}
                  key={i.shopify_product_id}
                  title={i.product_title}
                >
                  <Radio value={i.shopify_product_id}>{i.product_title}</Radio>
                </div>
              ))}
            </Radio.Group>
          </div>
        </Spin>
        <div style={{ textAlign: 'right' }}>
          <Button
            disabled={selected === undefined}
            type="primary"
            onClick={handleOpenMapping}
            loading={mappingLoading}
          >
            {intl.get('bargain.banner2.go_mapping')}
          </Button>
        </div>
      </Modal>
      <Mapping
        visible={mappingVisible}
        originProductData={mappingData.data}
        fbdSupply={mappingData.supply}
        onClose={handleMappingClose}
        disableBanner
      />
    </>
  );
}

export default connect(null)(React.memo(Banner2));
