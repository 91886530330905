import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Button, Select, Form, Row, Col, Input, Alert } from 'antd';
import { connect } from 'dva';
import { Link } from 'dva/router';
import intl from 'react-intl-universal';

import { Post } from '../../../utils/request';
import styles from './BargainModal.less';

import { emailRegExp } from '../../../utils/constants';
import SuccessFeedback from './SuccessFeedback';
import NumericInput from './NumericInput';
import { replaceUrlToHttps } from 'utils/utils';

const BargainModal = ({
  data: { visible, data },
  userBargainDisable,
  onCancel,
  onSuccess,
  form,
  userInfo,
  country,
  logistic
}) => {
  console.log('--------- BargainModal --------');

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [freight, setFreight] = useState(null);
  const [shipping, setShipping] = useState(undefined);

  const disable = useMemo(() => {
    return userBargainDisable || (data && data.bargained);
  }, [userBargainDisable, data]);

  useEffect(() => {
    if (visible) {
      form.validateFields();
    } else {
      // eslint-disable-next-line
      resetState();
    }
  }, [visible]);

  const shippingCost = useMemo(() => {
    if (!(freight && shipping)) {
      return -1;
    }

    const target = freight.find(i => i.serviceName === shipping);
    return (
      target && target.freightAmount && target.freightAmount.formatedAmount
    );
  }, [freight, shipping]);

  const validStore = useMemo(() => {
    if (!(userInfo && userInfo.stores)) {
      return [];
    }

    return userInfo.stores.filter(i => i.status !== 2);
  }, [userInfo]);

  function resetState() {
    setSubmitted(false);
  }

  function handleCancel() {
    onCancel();
  }

  function handleSubmit(e) {
    e.preventDefault();
    const { validateFields } = form;
    validateFields(async (errors, values) => {
      if (errors) {
        return;
      }

      setLoading(true);

      const { per_orders: order, bargaining: price, ...rest } = values;

      try {
        const res = await Post('/prod/propool/bargain', {
          data: {
            ...rest,
            // per_orders: Number(order),
            bargaining: Number(price),
            ae_product_id: data.id,
            title: data.title
          }
        });
        if (res.code === 2010) {
          onSuccess(data);
          setSubmitted(true);
          form.resetFields();
        }
      } catch (error) {
        console.log('handleSubmit', error);
      }

      setLoading(false);
    });
  }

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  function validateStatus(filedName) {
    return form.isFieldTouched(filedName) && form.getFieldError(filedName);
  }

  async function handleCountryChange(value) {
    if (!value) {
      setFreight([]);
      return;
    }

    try {
      const res = await Post('/prod/freight', {
        data: {
          country_code: value,
          prod_id: data.id,
          quantity: 1,
          sku: data.sku
        }
      });

      if (res.code === 2000) {
        setFreight((res.data && res.data.freight) || []);
      }
    } catch (error) {
      console.log('handleCountryChange error:', error);
    }
  }

  function handleShippingChange(value) {
    if (!value) {
      setShipping(undefined);
      return;
    }

    const service = value.split(',')[0];
    console.log('handleShippingChange', service);
    setShipping(service);
  }

  function commentCounter() {
    const tmp = form.getFieldValue('message');
    const length = (tmp && tmp.length) || 0;
    return `${length}/500`;
  }

  const { getFieldDecorator, getFieldsError } = form;

  const emailError = validateStatus('email');
  const orderError = validateStatus('per_orders');
  const shippingError = validateStatus('shipping_service');
  const commentError = validateStatus('message');
  const storeError = validateStatus('store_id');
  const countryError = validateStatus('target_country');
  const priceError = validateStatus('bargaining');

  if (!data) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      width={780}
      maskClosable={false}
      keyboard={false}
      onCancel={handleCancel}
      bodyStyle={{ padding: '36px 36px' }}
      destroyOnClose
    >
      {submitted ? (
        <SuccessFeedback />
      ) : (
        <>
          <h2 className={styles.title}>{intl.get('bargain.bargain')}</h2>
          {validStore.length === 0 ? (
            <div className={styles.notStore}>
              <span>{intl.get('bargain.not_store')}</span>
              <Link to="/bind/link-store">
                <Button type="primary">{intl.get('bargain.link_store')}</Button>
              </Link>
            </div>
          ) : null}
          {/* {disable ? (
            <Alert
              message="You can submit bargain 10 times per 24 hours."
              type="info"
              showIcon
              style={{ marginBottom: 12 }}
            />
          ) : null} */}
          <div className={styles.prodInfo}>
            <img className={styles.img} src={replaceUrlToHttps(data.image)} alt="" />
            <div className={styles.infoRight}>
              <p>{intl.get('bargain.product_name')}</p>
              <div>
                <a
                  href={data.url}
                  target="_blank"
                  className={styles.link}
                  title={data.title}
                >
                  {data.title}
                </a>
              </div>
              <p>{intl.get('bargain.supplier_price')}</p>
              <div className={styles.supplier}>
                <span className={styles.price}>{`US$ ${
                  isNaN(data.cost) ? '--' : data.cost
                }`}</span>
                {/* <div>
                  <span style={{ marginRight: 4 }}>Shipping cost:</span>
                  {shippingCost === -1 ? (
                    <span>Select a target country and shipping service.</span>
                  ) : shippingCost ? (
                    <span>{shippingCost}</span>
                  ) : (
                    <span>We will get back to you with this info in email.</span>
                  )}
                </div> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit} layout="vertical">
            <Row gutter={48}>
              <Col md={12}>
                <Form.Item
                  label={intl.get('bargain.email_field')}
                  validateStatus={emailError ? 'error' : ''}
                  help={emailError || ''}
                >
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true, message: 'required' },
                      {
                        pattern: emailRegExp,
                        message: intl.get('bargain.input_email')
                      },
                      {
                        max: 500,
                        message: intl.get('bargain.fill_max', { max: 500 })
                      }
                    ]
                  })(
                    <Input
                      placeholder={intl.get('bargain.email_field_p')}
                      type="email"
                      disabled={disable || validStore.length === 0}
                    />
                  )}
                </Form.Item>
                {/* <Form.Item
                  label="Estimated orders for the product per day"
                  validateStatus={orderError ? 'error' : ''}
                  help={orderError || ''}
                >
                  {getFieldDecorator('per_orders', {
                    rules: [
                      { required: true, message: 'required' },
                      {
                        max: 50,
                        message: 'Please fill in maximum 50 characters. '
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (value && Number(value) < 0) {
                            callback('Must be integer');
                          } else if (value && !isInt(value)) {
                            callback('Must be integer');
                          }
                          callback();
                        }
                      }
                    ]
                  })(
                    <NumericInput
                      placeholder="Amount of orders you sell for this product"
                      disabled={disable || validStore.length === 0}
                      addonAfter="Orders"
                    />
                  )}
                </Form.Item> */}
                {/* <Form.Item
                  label="Preferred shipping service"
                  validateStatus={shippingError ? 'error' : ''}
                  help={shippingError || ''}
                >
                  {getFieldDecorator('shipping_service', {
                    rules: [{ required: true, message: 'required' }]
                  })(
                    <Select
                      placeholder="Please select preferred shipping service"
                      allowClear
                      showSearch
                      disabled={disable || validStore.length === 0}
                      onChange={handleShippingChange}
                    >
                      {logistic &&
                        logistic.map(i => (
                          <Select.Option
                            key={i.id}
                            value={`${i.ServiceName},${i.Name}`}
                          >
                            {i.Name}
                          </Select.Option>
                        ))}
                    </Select>
                  )}
                </Form.Item> */}
                <Form.Item
                  label={intl.get('bargain.message_field')}
                  validateStatus={commentError ? 'error' : ''}
                  help={commentError || ''}
                  extra={commentCounter()}
                  className={styles.comment}
                >
                  {getFieldDecorator('message', {
                    rules: [
                      {
                        max: 500,
                        message: intl.get('bargain.fill_max', { max: 500 })
                      }
                    ]
                  })(
                    <Input.TextArea
                      rows={3}
                      placeholder={intl.get('bargain.message_field_p')}
                      disabled={disable || validStore.length === 0}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col md={12}>
                {/* <Form.Item
                  label="Select a store"
                  validateStatus={storeError ? 'error' : ''}
                  help={storeError || ''}
                >
                  {getFieldDecorator('store_id', {
                    rules: [{ required: true, message: 'required' }]
                  })(
                    <Select
                      placeholder="Please select a store"
                      allowClear
                      disabled={disable || validStore.length === 0}
                    >
                      {validStore.map(i => (
                        <Select.Option key={i.id} value={i.id}>
                          {i.shop_name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item> */}
                {/* <Form.Item
                  label="Select a target country"
                  validateStatus={countryError ? 'error' : ''}
                  help={countryError || ''}
                >
                  {getFieldDecorator('target_country', {
                    rules: [{ required: true, message: 'required' }]
                  })(
                    <Select
                      placeholder="Please select a target country"
                      allowClear
                      showSearch
                      disabled={disable || validStore.length === 0}
                      onChange={handleCountryChange}
                    >
                      {country &&
                        country.map(i => (
                          <Select.Option key={i.Code} value={i.Name}>
                            {i.Name}
                          </Select.Option>
                        ))}
                    </Select>
                  )}
                </Form.Item> */}
                <Form.Item
                  label={intl.get('bargain.price_field')}
                  validateStatus={priceError ? 'error' : ''}
                  help={priceError || ''}
                  //     extra="Bargain margin highly depends on your DSers account performance,
                  // such as the amount of orders you processed to AliExpress via
                  // DSers,etc."
                >
                  {getFieldDecorator('bargaining', {
                    rules: [{ required: true, message: 'required' }]
                  })(
                    <NumericInput
                      placeholder={intl.get('bargain.price_field_p')}
                      disabled={disable || validStore.length === 0}
                      addonBefore="$"
                    />
                  )}
                </Form.Item>
                <div className={styles.footer}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={hasErrors(getFieldsError())}
                    loading={loading}
                  >
                    {intl.get('bargain.submit')}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          <div className={styles.notStore}>
            <span>{intl.get('bargain.submission_tip')}</span>
          </div>
        </>
      )}
    </Modal>
  );
};

export default connect(({ login, dropshippersetting }) => ({
  userInfo: login.userInfo,
  country: dropshippersetting.countrys,
  logistic: dropshippersetting.logistics_services
}))(Form.create()(React.memo(BargainModal)));
