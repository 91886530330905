import React from 'react';
import CardItem from './CardItem';
import styles from './index.less';

function FilterCard({ onChange, filters, currentSelected }) {
  return (
    <div className={styles.card}>
      {filters.map((item, index) => {
        return !item.hidden ? (
          <CardItem
            option={item.option}
            title={item.title}
            titleTip={item.titleTip || null}
            key={item.key}
            filterKey={item.key}
            currentSelected={currentSelected[index]}
            onSelected={onChange}
          />
        ) : null;
      })}
    </div>
  );
}

FilterCard.propTypes = {};

export default React.memo(FilterCard);
