import { useState, useCallback } from 'react';

function useActionCount() {
  const [action, setAction] = useState(null);
  const pushAction = useCallback(
    newAction => {
      setAction({
        ...newAction,
        actionCount: action ? action.actionCount + 1 : 1
      });
    },
    [action]
  );
  return [action, pushAction];
}

export default useActionCount;
