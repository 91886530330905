import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { DSERS_IMG_HOST } from 'utils/constants';
import { CommonDataCollect } from 'utils/utils';

import styles from './BestSellerBanner.less';

const BestSellerBanner = ({ lang, active, onChange }) => {

  const handleClickBanner = ({ lang }) => {
    CommonDataCollect({
      action: 'tmall_order_cold_startup_find_supplier_banner_click',
      event_type: 'tmall_order_cold_startup'
    });
    onChange(!active)
  };

  return (
    // <a
    //   href="https://www.dsers.com/blog/product-update-dropship-with-agencies/"
    //   target="_blank"
    //   onClick={handleClickBanner}
    //   className={styles.bannerLink}
    // >
    <div onClick={handleClickBanner} className={classNames(`${styles.container} ${lang}`, { [styles.active]: active })}>
      <img
        className={styles.leftImg}
        width="180"
        src={`${DSERS_IMG_HOST}/shopify/activity/tmall_cold_startup/221019/tmall_findsupplier_banner_img_1.png`}
      />

      <img
        className={styles.rightImg}
        src={`${DSERS_IMG_HOST}/shopify/activity/tmall_cold_startup/221019/tmall_findsupplier_banner_img_3.png`}
        width="231"
      />
      <div className={styles.titleContainer}>
        <div className={styles.title}>{intl.get('banner.tmall_cold_startup.find_supplier_banner_title')}</div>
        <div className={styles.desc}>
          <div>{intl.get('banner.tmall_cold_startup.find_supplier_banner_subtitle')}</div>
          <div>{intl.get('banner.tmall_cold_startup.find_supplier_banner_subtitle_2')}</div>
        </div>
        <img
          className={styles.middleImg}
          src={`${DSERS_IMG_HOST}/shopify/activity/tmall_cold_startup/221019/tmall_findsupplier_banner_img_2.png`}
          width="69"
        />
        <div className={styles.descLine}></div>
      </div>

      {/* <div className={styles.button}>
  {intl.get('banner.agency_cold_startup.learn_more_btn')}
  <img
    className={styles.buttonArrow}
    width="17"
    src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_cold_startup_btn_arrow.png`}
  />
</div> */}
    </div>
    // </a>
  );
};


export default BestSellerBanner;
