import React, { useState, useEffect } from 'react';

import { Get } from 'utils/request';
import { getLanguage } from 'utils/utils';
import { dsersBFFApiHost } from 'common/sysconfig';
import { promises } from 'dns';

const configKeyMap = {
  area_filter_bargain: {
    typeCode: 'partition'
  },
  hot_word_bargain: {
    typeCode: 'hotword'
  },
  category_bargain: {
    typeCode: 'category'
  },
  ship_from_bargain: {
    typeCode: 'shipping_from'
  }
};

function useConfigData(key) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [areaFilters,setAreaFilters] = useState([]);

  useEffect(() => {
    // 只是优化策略，避免闪烁
    const config = configKeyMap[key];
    if (!config) {
      setData([]);
    }
    try {
      const cacheData = JSON.parse(sessionStorage.getItem(key));
      if (cacheData) {
        setData(cacheData);
      }
    } catch (error) {
      console.warn('get cache data error', error);
    }

    async function fetchData() {
      setLoading(true);
      try {
        const payload = {
          data: {
            lang: getLanguage().replace('-', '_'),
            type_code: config.typeCode
          }
        };
        const res = await Get(
          '/onedata_propool/product_pool/dict/list',
          payload
        );

        if (res.code === 2000 && res.data) {
          const dataArray = res.data?.content || [];
          setData(dataArray);
          sessionStorage.setItem(key, JSON.stringify(dataArray));
        }
      } catch (error) {
        console.log('fetch error:', error);
      }
      setLoading(false);
    }
    
    async function fetchAreas(){
      setLoading(true);
      try {
        const payload = {
          data: {
            lang: getLanguage().replace('-', '_')
          }
        };

        // const res = await Get( 
        //   `${dsersBFFApiHost}/dsers-product-bff/product-pool/product/category`,
        //   { 
        //     isCustomApi:true 
        //   } 
        // );
        // const banner = await Get(
        //   `https://atc.dsers.com/api/v1/tmall/second/banner`,
        //   {
        //     isCustomApi:true,
        //   } 
        // )
        const [result1, result2] = await Promise.all([Get( 
          `${dsersBFFApiHost}/dsers-product-bff/product-pool/product/category`,
          { 
            isCustomApi:true 
          } 
        ),
        Get(
          `${window.DSERS_API_CONFIG.HOST}/api/v1/tmall/second/banner`,
          {
            isCustomApi:true,
          } 
        )]);
        
        const cates = result2.data?.map(({ id, content, img_url }) => ({
          categoryId: id.toString(),
          name: content,
          imageUrl: img_url,
          subCategory: [],
          type:'small'
        })) || [];
        const allList = [...cates, ...result1.category]

        if (allList.length) {
          setAreaFilters(allList);
        }
      } catch (error) {
        console.log('fetch error:', error);
      }
    }
    fetchData();
    fetchAreas();
  }, [key]);

  return [data, loading, areaFilters]
}

export default useConfigData;
