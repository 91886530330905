import React from 'react';
import intl from 'react-intl-universal';

import styles from './BannerItem.less';

function BannerItem({ style, highlight, feature, price, percent }) {
  const smallFont = intl.getInitOptions().currentLocale === 'pt-PT';
  const smallStyleProp = {
    style: smallFont ? { fontSize: 15, lineHeight: '26px' } : null
  };
  return (
    <div
      className={
        highlight
          ? `${styles.bannerItem} ${styles.highlight}`
          : styles.bannerItem
      }
      style={style}
    >
      {highlight ? (
        <>
          <div className={styles.row1}>{intl.get('bargain.powered_by')}</div>
          <div className={styles.row2} style={smallStyleProp.style}>
            {intl.get('bargain.mass_supply')}
          </div>
          <div className={styles.row3}>${price}</div>
          <div className={styles.row4}>
            {intl.get('bargain.banner_item.save_percent', { percent })}
          </div>
        </>
      ) : (
        <>
          <div className={styles.row1}>
            {intl.get('bargain.banner_item.provided_by')}
          </div>
          <div className={styles.row2}>
            {intl.get('bargain.banner_item.current_sp')}
          </div>
          <div className={styles.row3}>${price}</div>
          <div className={styles.row4}>
            {intl.get('bargain.banner_item.unstable')}
          </div>
        </>
      )}
      <ul className={styles.feature}>
        {feature.map(i => (
          <li key={i}>{i}</li>
        ))}
      </ul>
    </div>
  );
}

BannerItem.defaultProps = {
  highlight: false
};

export default BannerItem;
