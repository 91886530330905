import React from 'react';
import intl from 'react-intl-universal';
import { Tooltip } from 'antd';

import styles from './index.less';
import deliveryIcon from '../../assets/bargain/shipping_speed.svg';
import rewardIcon from '../../assets/bargain/dollor.svg';
import aeImg from '../../assets/icon-ae.jpg';
import dsersImg from '../../assets/bargain/dsers_icon.svg';
import dsersAeImg from '../../assets/bargain/dsers_ae_icon.svg';
import mainIcon from 'assets/newImportList/main_product.png'

import { getBaseLayoutPopupContainer } from 'utils/utils';

export const TendaysDeliveryTag = React.memo(() => {
  return (
    <div className={`${styles.deliveryMark} ${styles.tagInImportList}`}>
      <img src={deliveryIcon} />
      {intl.get('bargain.tag.delivery')}
    </div>
  );
});

export const RewardTag = React.memo(() => {
  return (
    <div className={`${styles.rewardMark} ${styles.tagInImportList}`}>
      <img src={rewardIcon} />
      {intl.get('bargain.tag.reward')}
    </div>
  );
});

export const TitleIcon = React.memo(
  ({ isDSers, className, hidden, inProductPool }) => {
    if (hidden) {
      return null;
    }

    return inProductPool ? (
      <Tooltip
        getPopupContainer={getBaseLayoutPopupContainer}
        overlay={
          !isDSers
            ? intl.get('bargain.ae_product_tip')
            : intl.get('bargain.dsers_product_tip')
        }
      >
        <img className={className} src={isDSers ? dsersImg : dsersAeImg} />
      </Tooltip>
    ) : (
      <img className={className} src={aeImg} />
    );
  }
);
