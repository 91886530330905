import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'dva';
import { Carousel, Icon, Tooltip } from 'antd';
import _ from 'lodash'
import styles from './AreaFilter.less';
import expectMoreImg from '../../../../../assets/bargain/expect_more.png';
import arrowSvg from '../../../../../assets/bargain/arrow.svg';
import './AreaFilter.css'
import Customized_banner from 'assets/bargain/Customized_banner.png'
import {FindSupplierUser} from 'utils/bannerWhiteList'

const PreArrow = ({ onClick, className, style, disabled }) => {
  return !disabled && (
    <div
      role="button"
      tabIndex="-1"
      onClick={disabled ? null : onClick}
      className={`${className} prevArrow ${disabled ? 'slick-disabled' : ''
        }`}
      style={style}
    >
      <i class="material-icons notranslate">arrow_left</i>
    </div>
  );
};

const NextArrow = ({ onClick, className, style, disabled }) => {
  return (
    <div
      role="button"
      tabIndex="-1"
      onClick={disabled ? null : onClick}
      className={` ${className} nextArrow ${disabled ? 'slick-disabled' : ''
        }`}
      style={style}
    >
      <i class="material-icons notranslate">arrow_right</i>
    </div>
  );
};

function AreaFilter({ onChange, handleClick, currentArea, areas = [], showShadow, handleClick_second, secondAreaList,userInfo, isSaintsTheme }) {
  const [disabledNext, setDisabledNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disabledNext_2, setDisabledNext_2] = useState(false);
  const [disablePrev_2, setDisablePrev_2] = useState(false);
  const [displayBanner, setDisplayBanner] = useState(4);
  // const [areasCode, setAreasCode] = useState('');
  const [isCustomizedUser,setIsCustomizedUser] = useState(FindSupplierUser.includes(userInfo.user_id))
  const carouselRef_2 = useRef(null)
  const secondArray = areas.filter(item => {
        return item.type === 2
  })
  const secondFilterArray = secondArray.filter(item=>{
    if (secondAreaList?.includes(item.code)) {
      return item
    }
  })
  const firstFilterArrary = areas.filter(item=>{
    return item.type === 1
  })
  const beforeChange2 = (preIndex, nextIndex) => {
    const next = nextIndex >= areas.length - 3;
    const prev = nextIndex <= 0;
    setDisabledNext_2(next);
    setDisablePrev_2(prev);
  }

  useEffect(()=>{
    console.log(secondAreaList,secondArray,secondFilterArray,'?????????????????');
    if(localStorage.getItem('isCustomizedUser')){
      setIsCustomizedUser(true)
    }
  })

  useEffect(()=>{
    let lunbo = document.getElementsByClassName('lunbo')[0]
    lunbo.style.left = 0
  },[secondAreaList])

  useEffect(() => {
    listenBanner();

    addEventListener('resize', listenBanner)

    return function clean (){
      removeEventListener('resize', listenBanner);
    }
  }, [])

  /**
   * 
   *  监听屏幕宽度动态改变banner漏出数量
   */
  const listenBanner = () => {
    const screen = document.body.clientWidth;
    if (Number(screen) >= 1600) {
      setDisplayBanner(5)
    } else {
      setDisplayBanner(4)
    }
  }


  const onPrev = () => {
    let lunbo = document.getElementsByClassName('lunbo')[0]
    let newLeft = parseInt(lunbo.style.left) || 0
    if(parseInt(lunbo.style.left) >= 0){
      return
    }else if(parseInt(lunbo.style.left)  >= -217 ){
      lunbo.style.left = newLeft + 217 + 'px'
    }else if(parseInt(lunbo.style.left) < -217){
      lunbo.style.left = newLeft + 81 + 'px'
    }

    return true;
  };

  const onNext = () => {
    let lunbo = document.getElementsByClassName('lunbo')[0]
    let newLeft = parseInt(lunbo.style.left) || 0
    const secondLength = secondFilterArray.length ? secondFilterArray.length : secondArray.length
    if(parseInt(lunbo.style.left) <= -(secondLength * 3)) {
      return
    }else{
      if(parseInt(lunbo.style.left) > -217 ){
        lunbo.style.left = newLeft - 217 + 'px'
      }else{
        lunbo.style.left = newLeft - 81 + 'px'
      }
    }


    return true;
  };

  const onPrev_2 = () => {
    if (!carouselRef_2.current) {
      return false;
    }
    carouselRef_2.current.slick.slickPrev()
    return false
  }

  const onNext_2 = () => {
    if (!carouselRef_2.current) {
      return false;
    }
    carouselRef_2.current.slick.slickNext()
    return true;
  };

  return (
    <div className={styles.areaFilterContainer}>

      <Carousel
        dots={false}
        infinite={false}
        beforeChange={beforeChange2}
        variableWidth={false}
        slidesToScroll={1}
        slidesToShow={displayBanner}
        draggable={false}
        ref={carouselRef_2}
        style={{ overflow: 'hidden' }}
        className={styles.carousel_1}
      >
        {areas.map((item, index) => {
          console.log(_.findIndex(currentArea, item),'22222')
          if (item.type === 1) {
            return (
              <Tooltip title={item.value} key={item.code}>
                <div className={styles.areaFilterItemCon}>

                <div className={`${styles.areaFilterItem} ${_.findIndex(currentArea, item) !== -1 ? styles.active : ''
                      } ${isSaintsTheme ? styles.saintsthemeStyle : null}`}
                      style={{ width: '208px', marginRight: '9px', border: _.findIndex(currentArea, item) !== -1 ? '2px #fff solid' : 'none' }}>
                  <div
                    role="button"
                    tabIndex="-1"
                    onClick={() => handleClick(item)}

                    className={`${styles.areaFilterItem1} `}
                    
                  >
                    <img src={item.image} />
                    {_.findIndex(currentArea, item) !== -1 && (
                      <div className={styles.areaDone}><i class="material-icons">done</i></div>
                    )}
                    {_.findIndex(currentArea, item) !== -1 && (
                      <div className={styles.clickCon} />
                    )}
                  </div>
                </div>
                </div>
              </Tooltip>
            );
          }
        })}
      </Carousel>
      {isCustomizedUser ? 
      <div className={`${styles.carousel_2} carousel_2`} >
        <div className={`${styles.areaList} lunbo`} style={_.findIndex(areas, { type: 2 }) === -1 ? { height: "0" } : null}>
          {!secondAreaList?.length ?
            <>
              <>
                <Tooltip title={'Customized'}>
                  <div
                    role="button"
                    tabIndex="-1"
                    className={`${styles.areaFilterItem_second} ${_.findIndex(currentArea, { code: 'Customized Package', value: 'Customized Package', type: 2 }) !== -1 ? styles.active : ''
                      }`}
                    onClick={() => handleClick_second({ code: 'Customized Package', value: 'Customized Package', type: 2 })}
                    style={{ marginRight: '9px', width: '208px' }}
                  >
                    <div className='imgBox'>
                      <img src={Customized_banner} className='lageImg'
                      />
                    </div>
                    {_.findIndex(currentArea, { code: 'Customized Package', value: 'Customized Package', type: 2 }) !== -1 && (
                      <div className={styles.areaDone}><i class="material-icons">done</i></div>
                    )}
                    {_.findIndex(currentArea, { code: 'Customized Package', value: 'Customized Package', type: 2 }) !== -1 && (
                      <div className={styles.clickCon} />
                    )}
                  </div>
                </Tooltip></>
              <>
                {secondArray.map((item, index) => {
                  return (
                    <Tooltip title={item.value} key={item.code + index}>
                      <div
                        role="button"
                        tabIndex="-1"
                        onClick={() => handleClick_second(item)}
                        className={`${styles.areaFilterItem_second} ${_.findIndex(currentArea, item) !== -1 ? styles.active : ''
                          }`}
                        style={{ marginRight: '9px', width: index <= 0 ? '208px' : '72px' }}
                        onMouseEnter={index > 0 ? (e) => {
                          e.currentTarget.style.width = '208px'
                          e.currentTarget.style.transition = '0.5s'
                        } : null}
                        onMouseLeave={index > 0 ? (e) => {
                          e.currentTarget.style.width = '72px'
                          e.currentTarget.style.transition = '0.5s'
                        } : null}
                      >
                        <div className='imgBox'>
                          <img src={item.image} className='lageImg'
                          />
                        </div>
                        {_.findIndex(currentArea, item) !== -1 && (
                          <div className={styles.areaDone}><i class="material-icons">done</i></div>
                        )}
                        {_.findIndex(currentArea, item) !== -1 && (
                          <div className={styles.clickCon} />
                        )}
                      </div>
                    </Tooltip>
                  );
                })}
              </>
            </>
            :
            <>
              <>
                <Tooltip title={'Customized'}>
                  <div
                    role="button"
                    tabIndex="-1"
                    className={`${styles.areaFilterItem_second} ${_.findIndex(currentArea, { code: 'Customized Package', value: 'Customized Package', type: 2 }) !== -1 ? styles.active : ''
                      }`}
                    onClick={() => handleClick_second({ code: 'Customized Package', value: 'Customized Package', type: 2 })}
                    style={{ marginRight: '9px', width: '208px' }}
                  >
                    <div className='imgBox'>
                    <img src={Customized_banner} className='lageImg'
                      />
                    </div>
                    {_.findIndex(currentArea, { code: 'Customized Package', value: 'Customized Package', type: 2 }) !== -1 && (
                      <div className={styles.areaDone}><i class="material-icons">done</i></div>
                    )}
                    {_.findIndex(currentArea, { code: 'Customized Package', value: 'Customized Package', type: 2 }) !== -1 && (
                      <div className={styles.clickCon} />
                    )}
                  </div>
                </Tooltip>
              </>
              <>
                {secondFilterArray.map((item, index) => {
                  return (
                    <Tooltip title={item.value} key={item.code + index}>
                      <div
                        role="button"
                        tabIndex="-1"
                        onClick={() => handleClick_second(item)}
                        className={`${styles.areaFilterItem_second} ${_.findIndex(currentArea, item) !== -1 ? styles.active : ''
                          }`}
                        style={{ marginRight: '9px', width: index <= 0 ? '208px' : '72px' }}
                        onMouseEnter={index > 0 ? (e) => {
                          e.currentTarget.style.width = '208px'
                          e.currentTarget.style.transition = '0.5s'
                        } : null}
                        onMouseLeave={index > 0 ? (e) => {
                          e.currentTarget.style.width = '72px'
                          e.currentTarget.style.transition = '0.5s'
                        } : null}
                      >
                        <div className='imgBox'>
                          <img src={item.image} className='lageImg'
                          />
                        </div>
                        {_.findIndex(currentArea, item) !== -1 && (
                          <div className={styles.areaDone}><i class="material-icons">done</i></div>
                        )}
                        {_.findIndex(currentArea, item) !== -1 && (
                          <div className={styles.clickCon} />
                        )}
                      </div>
                    </Tooltip>
                  );
                })}
              </>
            </>
          }
          {/* {!areas.length
          ? [0, 1, 2, 4].map(item => {
            return (
              <div
                key={item}
                className={styles.areaFilterItem}
                style={{ width: '264px', marginRight: '18px' }}
              />
            );
          })
          : null} */}
        </div>

      </div>
      :
      <div className={`${styles.carousel_2} carousel_2`} >
        <div className={`${styles.areaList} lunbo`} style={_.findIndex(areas,{type : 2}) === -1 ? {height:"0"} : null}>
          {!secondAreaList?.length ? secondArray.map((item, index) => {
              return (
                <Tooltip title={item.value} key={item.code + index}>
                  <div                  
                    role="button"
                    tabIndex="-1"
                    onClick={() => handleClick_second(item)}
                    className={`${styles.areaFilterItem_second} ${_.findIndex(currentArea, item) !== -1 ? styles.active : ''
                      }`}
                    style={{ marginRight: '9px', width: index <1 ? '208px' : '72px' }}
                    onMouseEnter={index > 0 ? (e) => {
                      e.currentTarget.style.width = '208px'
                      e.currentTarget.style.transition = '0.5s'
                    } : null}
                    onMouseLeave={index > 0 ? (e) => {
                      e.currentTarget.style.width = '72px'
                      e.currentTarget.style.transition = '0.5s'
                    } : null}
                  >
                    <div className='imgBox'>
                      <img src={item.image} className='lageImg'
                      />
                    </div>
                    {_.findIndex(currentArea, item) !== -1 && (
                      <div className={styles.areaDone}><i class="material-icons">done</i></div>
                    )}
                    {_.findIndex(currentArea, item) !== -1 && (
                      <div className={styles.clickCon} />
                    )}
                  </div>
                </Tooltip>
              );
          })
            :
            secondFilterArray.map((item, index) => {
              console.log(currentArea,'111111')
                return (
                  <Tooltip title={item.value} key={item.code + index}>
                    <div                     
                      role="button"
                      tabIndex="-1"
                      onClick={() => handleClick_second(item)}
                      className={`${styles.areaFilterItem_second} ${_.findIndex(currentArea, item) !== -1 ? styles.active : ''
                        }`}
                      style={{ marginRight: '9px', width: index <1 ? '208px' : '72px'}}
                      onMouseEnter={index >= 1 ? (e) => {
                        e.currentTarget.style.width = '208px'
                        e.currentTarget.style.transition = '0.5s'
                      } : null}
                      onMouseLeave={index >= 1 ? (e) => {
                        e.currentTarget.style.width = '72px'
                        e.currentTarget.style.transition = '0.5s'
                      } : null}
                    >
                      <div className='imgBox'>
                        <img src={item.image} className='lageImg'
                        />
                      </div>
                      {_.findIndex(currentArea, item) !== -1 && (
                        <div className={styles.areaDone}><i class="material-icons">done</i></div>
                      )}
                      {_.findIndex(currentArea, item) !== -1 && (
                        <div className={styles.clickCon} />
                      )}
                    </div>
                  </Tooltip>
                );
            })
          }
          {/* {!areas.length
          ? [0, 1, 2, 4].map(item => {
            return (
              <div
                key={item}
                className={styles.areaFilterItem}
                style={{ width: '264px', marginRight: '18px' }}
              />
            );
          })
          : null} */}
      </div>
    </div>
      }

      {   
      secondFilterArray.length ? secondFilterArray.length > 7 ? 
      (
        <div className='arrows' style={firstFilterArrary.length ? null : {top:'5px'}}>
          <PreArrow onClick={onPrev} />
          <NextArrow onClick={onNext} />
        </div>
      ) : null
      : secondArray.length > 7 ? (
        <div className='arrows' style={firstFilterArrary.length ? null : {top:'5px'}}>
          <PreArrow onClick={onPrev} />
          <NextArrow onClick={onNext} />
        </div>
      ) : null}
      {firstFilterArrary.length > 3 ? (
        <div className='arrows_2'>
          <PreArrow onClick={onPrev_2} disabled={disablePrev_2} />
          <NextArrow onClick={onNext_2} />
        </div>
      ) : null}
    </div>
  );
}

export default connect(({ login, global }) => ({
  userInfo: login.userInfo,
  isSaintsTheme: global.themeStatus
}))(React.memo(AreaFilter));
