import React from 'react';
import { Button, Card, List } from 'antd';
import intl from 'react-intl-universal';
import { replaceUrlToHttps } from 'utils/utils';
import { list_V5, getVersion } from 'utils/bannerWhiteList';

import styles from './BannerCard.less';
import adIcon from '../../../assets/bargain/ad.png';
import discountIcon from '../../../assets/bargain/discount.png';

const ListItem = List.Item;

function BannerCard({ data, handleBannerClick, userId }) {
  const {
    dsers_url,
    dsers_img,
    ae_img,
    ae_product_title,
    dsers_product_title,
    ae_price,
    dsers_price,
    percent
  } = data;

  const isBannerUser = getVersion(userId);
  const isV5 = list_V5.includes(userId);
  const img = ae_img?.split(';');

  return isBannerUser !== 'notBannerUser' ? (
    <Card
      bordered={false}
      cover={
        <img
          alt={dsers_product_title}
          src={replaceUrlToHttps(img[0])}
          className={styles.img}
          onClick={() => {
            window.dataLayer.push({
              event: 'compareProductMassEvent',
              type: 'clickIssue'
            });
            window.open(dsers_url, '_blank');
          }}
        />
      }
      className={styles.bannerCard}
      data-label="product-item"
    >
      <div className={styles.bannerCon}>
        <div className={styles.bannerInfo}>
          <a
            href={dsers_url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              window.dataLayer.push({
                event: 'compareProductMassEvent',
                type: 'clickIssue'
              });
            }}
          >
            <span className={styles.bannerTitle} title={dsers_product_title}>
              <img className={styles.ad} src={adIcon} />
              {dsers_product_title}
            </span>
          </a>
        </div>
        <div className={styles.descriptionCon}>
          <div className={styles.dsersPrice}>${dsers_price}</div>
          <span className={styles.currentPrice}>Current Price: ${ae_price}</span>
          <span className={styles.discountNum}>{percent}</span>
          <img className={styles.discount} src={discountIcon} />
          <Button
            onClick={() => handleBannerClick(data)}
            className={styles.cheaperBtn}
          >
            {/* <span>{intl.get('bargain.reward')}</span> */}
            <span>
              {isV5 ? 'up to 20% off' : intl.get('bargain.found_cheaper')}
            </span>
          </Button>
        </div>
      </div>
    </Card>
  ) : null;
}

export default BannerCard;
