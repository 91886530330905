import React from 'react';
import PropTypes from 'prop-types';

import styles from './CardItem.less';

const CardItem = ({
  option,
  onSelected,
  currentSelected,
  title,
  titleTip,
  filterKey
}) => {
  function handleClick(value) {
    if (value === option[currentSelected].value) {
      return;
    }
    onSelected(value, filterKey);
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {title}
        {titleTip || null}
      </div>
      <div className={styles.filter}>
        {option.map((i, index) => {
          if (typeof i === 'object') {
            return (
              <span
                key={i.value}
                tabIndex="-1"
                role="button"
                onClick={() => handleClick(i.value)}
                className={index === currentSelected ? styles.active : null}
              >
                {i.label}
              </span>
            );
          }
          return (
            <span
              key={i}
              tabIndex="-1"
              role="button"
              onClick={() => handleClick(i)}
              className={index === currentSelected ? styles.active : null}
            >
              {i}
            </span>
          );
        })}
      </div>
    </div>
  );
};

CardItem.defaultProps = {
  onSelected: () => {}
};

CardItem.propTypes = {
  option: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object)
  ]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onSelected: PropTypes.func
};

export default CardItem;
