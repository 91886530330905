import React from 'react'
import {Button} from 'antd'
import styles from './CustomizedArea.less'
import Customized_1 from 'assets/bargain/Customized_1.png'
import Customized_2 from 'assets/bargain/Customized_2.png'
import Customized_3 from 'assets/bargain/Customized_3.png'
import Customized_4 from 'assets/bargain/Customized_4.png'
import Customized_5 from 'assets/bargain/Customized_5.png'
import Customized_icon from 'assets/bargain/Customized_icon.png'
import card_1 from 'assets/bargain/card_1.png'
import card_2 from 'assets/bargain/card_2.png'
import card_3 from 'assets/bargain/card_3.png'
import yourlogo from 'assets/bargain/yourlogo.png'

export default function CustomizedArea() {
    return (
        <div className={styles.container}>
            <div className={styles.title}>Custom Branding Service</div>
            <div className={styles.process}>
                <div className={styles.Customized_1}>
                    <img src={Customized_1} alt="" />
                    <div>Sourcing <br/>
                        Quotation</div>
                </div>
                <div className={styles.Customized_icon}>
                    <img src={Customized_icon} alt="" />
                </div>
                <div className={styles.Customized_1}>
                    <img src={Customized_2} alt="" />
                    <div>Purchase <br/>
                        Quality check</div>
                </div>
                <div className={styles.Customized_icon}>
                    <img src={Customized_icon} alt="" />
                </div>
                <div className={styles.Customized_1}>
                    <img src={Customized_3} alt="" />
                    <div>Custom <br/>
                        packaging</div>
                </div>
                <div className={styles.Customized_icon}>
                    <img src={Customized_icon} alt="" />
                </div>
                <div className={styles.Customized_1}>
                    <img src={Customized_4} alt="" />
                    <div>Shipping</div>
                </div>
                <div className={styles.Customized_icon}>
                    <img src={Customized_icon} alt="" />
                </div>
                <div className={styles.Customized_1}>
                    <img src={Customized_5} alt="" />
                    <div>After-sales<br/>
                        service</div>
                </div>
            </div>
            <div className={styles.text}>
                <div>DSers is happy to provide customized packages to help your brand stand out!</div>
                <div>This is service is perfect for you if:</div>
                <div>-Your want an effective, easy and affordable solution to increase your brand recognition</div>
                <div>-Your want to personalize your customer experience when they receive their packages</div>
                <div>-Your want to create an unique and lasting experience with your customers to increase the repeat buying rate</div>
                <div>Add your brand's logo with DSers and get your customized packages now!</div>
                <a href={'mailto:issac@dserspro.com'}>Contact us to learn more</a>
            </div>
            <div className={styles.CustomizedBox}>
                <div className={styles.img}>
                    <img src={card_1} alt="" />
                </div>
                <div className={styles.subscript}>
                    <div>Customized Box</div>
                    <div>Customize the boxes your buyers will receive with your logo and more! Click below for more information</div>
                    <Button><a href={'mailto:issac@dserspro.com'}>CONTACT US</a></Button>
                </div>
            </div>
            <div className={styles.CustomizedBox}>
                <div className={styles.img}>
                  <img src={yourlogo} alt="" />
                </div>
                <div className={styles.subscript}>
                    <div>Customized Bag</div>
                    <div>Customize the bags used to ship your product with your logo and more! Click below for more information</div>
                    <Button><a href={'mailto:issac@dserspro.com'}>CONTACT US</a></Button>
                </div>                
            </div>
            <div className={styles.CustomizedBox}>
                <div className={styles.img}>
                    <img src={card_3} alt="" />
                </div>
                <div className={styles.subscript}>
                    <div>Customized Card</div>
                    <div>Add customized printed thank you cards to your packages to improve your branding! Click below for more information</div>
                    <Button><a href={'mailto:issac@dserspro.com'}>CONTACT US</a></Button>
                </div>   
            </div>
            <div className={styles.bottom}>
                <span></span><span>Feel free to contact us anytime at</span><span></span>
            </div>
            <div className={styles.contact}>
                  <a href={'mailto:issac@dserspro.com'}>issac@dserspro.com</a>
            </div>
        </div>
    )
}
