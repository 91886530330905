import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'dva';
import { Carousel, Icon, Tooltip } from 'antd';
import _ from 'lodash'
import styles from './AreaFilter.less';
import expectMoreImg from '../../../../../assets/bargain/expect_more.png';
import arrowSvg from '../../../../../assets/bargain/arrow.svg';
import './AreaFilter.css'
import Customized_banner from 'assets/bargain/Customized_banner.png'
import {FindSupplierUser} from 'utils/bannerWhiteList'

const PreArrow = ({ onClick, className, style, disabled }) => {
  return !disabled && (
    <div
      role="button"
      tabIndex="-1"
      onClick={disabled ? null : onClick}
      className={`${className} prevArrow ${disabled ? 'slick-disabled' : ''
        }`}
      style={style}
    >
      <i class="material-icons notranslate">arrow_left</i>
    </div>
  );
};

const NextArrow = ({ onClick, className, style, disabled }) => {
  return (
    <div
      role="button"
      tabIndex="-1"
      onClick={disabled ? null : onClick}
      className={` ${className} nextArrow ${disabled ? 'slick-disabled' : ''
        }`}
      style={style}
    >
      <i class="material-icons notranslate">arrow_right</i>
    </div>
  );
};

function AreaFilter({ onChange, handleClick, currentArea, areas = [], showShadow, handleClick_second, secondAreaList,userInfo, isSaintsTheme }) {
  const [disabledNext, setDisabledNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disabledNext_2, setDisabledNext_2] = useState(false);
  const [disablePrev_2, setDisablePrev_2] = useState(true);
  const [displayBanner, setDisplayBanner] = useState(4);
  // const [areasCode, setAreasCode] = useState('');
  const [isCustomizedUser,setIsCustomizedUser] = useState(FindSupplierUser.includes(userInfo.user_id))
  const carouselRef_2 = useRef(null)
  const secondArray = areas.filter(item => {
        return item.type === 2
  })
  const secondFilterArray = secondArray.filter(item=>{
    if (secondAreaList?.includes(item.code)) {
      return item
    }
  })
  const firstFilterArrary = areas
  const beforeChange2 = (preIndex, nextIndex) => {
    const next = nextIndex >= areas.length - 3;
    const prev = nextIndex <= 0;
    setDisabledNext_2(next);
    setDisablePrev_2(prev);
  }

  useEffect(()=>{
    if(localStorage.getItem('isCustomizedUser')){
      setIsCustomizedUser(true)
    }
  })

  useEffect(()=>{
    let lunbo = document.getElementsByClassName('lunbo')[0]
    lunbo.style.left = 0
  },[firstFilterArrary])

  useEffect(() => {
    listenBanner();

    addEventListener('resize', listenBanner)

    return function clean (){
      removeEventListener('resize', listenBanner);
    }
  }, [])

  /**
   * 
   *  监听屏幕宽度动态改变banner漏出数量
   */
  const listenBanner = () => {
    const screen = document.body.clientWidth;
    if (Number(screen) > 1600) {
      setDisplayBanner(5)
    } else {
      setDisplayBanner(4)
    }
  }


  const onPrev = () => {
    let lunbo = document.getElementsByClassName('lunbo')[0]
    let newLeft = parseInt(lunbo.style.left) || 0
    if(parseInt(lunbo.style.left) >= -220 ){
      setDisablePrev_2(true)
    }
    if(parseInt(lunbo.style.left) >= 0){
      return
    }else if(parseInt(lunbo.style.left)  >= -440 ){
      lunbo.style.left = newLeft + 220 + 'px'
    }else if(parseInt(lunbo.style.left) < -440){
      lunbo.style.left = newLeft + 84 + 'px'
    }

    return true;
  };

  const onNext = () => {
    let lunbo = document.getElementsByClassName('lunbo')[0]
    let newLeft = parseInt(lunbo.style.left) || 0
    setDisablePrev_2(false)
    let firstLength = 0
    firstFilterArrary.forEach((item,index)=>{
      if(index <= 1){
        firstLength += 220
        return
      }
      if(index <= firstFilterArrary.length - 7){
        firstLength += 84
      }
    })
    if(parseInt(lunbo.style.left) <= -firstLength) {
      return
    }else{
      if(parseInt(lunbo.style.left) >= -220 ){
        lunbo.style.left = newLeft - 220 + 'px'
      }else{
        lunbo.style.left = newLeft - 84 + 'px'
      }
    }


    return true;
  };

  const onPrev_2 = () => {
    if (!carouselRef_2.current) {
      return false;
    }
    carouselRef_2.current.slick.slickPrev()
    return false
  }

  const onNext_2 = () => {
    if (!carouselRef_2.current) {
      return false;
    }
    carouselRef_2.current.slick.slickNext()
    return true;
  };

  return (
    <div className={styles.areaFilterContainer}>
      <div className={`${styles.carousel_2} carousel_2`} >
        <div style={{width:`${areas.length * 72 + 416}px`}} className={`${styles.areaList} lunbo`}>
          {
            areas.map((item, index) => {
                return (
                  <Tooltip title={item.name} key={item.categoryId + index}>
                    <div                     
                      role="button"
                      tabIndex="-1"
                      onClick={() => handleClick_second(item)}
                      className={`${styles.areaFilterItem_second} ${_.findIndex(currentArea, item) !== -1 ? styles.active : ''
                        } ${isSaintsTheme ? styles.saintsthemeStyle : null}`}
                      style={{ marginRight: '12px', width: index <1 ? '208px' : '72px'}}
                      onMouseEnter={index > 0 ? (e) => {
                        e.currentTarget.style.width = '208px'
                        e.currentTarget.style.transition = '0.5s'
                      } : null}
                      onMouseLeave={index > 0 ? (e) => {
                        e.currentTarget.style.width = '72px'
                        e.currentTarget.style.transition = '0.5s'
                      } : null}
                    >
                      <div className='imgBox'>
                        <img src={item.imageUrl} className='lageImg'
                        />
                      </div>
                      {_.findIndex(currentArea, item) !== -1 && (
                        <div className={styles.areaDone}><i class="material-icons">done</i></div>
                      )}
                      {_.findIndex(currentArea, item) !== -1 && (
                        <div className={styles.clickCon} />
                      )}
                    </div>
                  </Tooltip>
                );
            })
          }
          {/* {!areas.length
          ? [0, 1, 2, 4].map(item => {
            return (
              <div
                key={item}
                className={styles.areaFilterItem}
                style={{ width: '264px', marginRight: '18px' }}
              />
            );
          })
          : null} */}
      </div>
    </div>

      {/* {   
      secondFilterArray.length ? secondFilterArray.length > 7 ? 
      (
        <div className='arrows' style={firstFilterArrary.length ? null : {top:'5px'}}>
          <PreArrow onClick={onPrev} />
          <NextArrow onClick={onNext} />
        </div>
      ) : null
      : secondArray.length > 7 ? (
        <div className='arrows' style={firstFilterArrary.length ? null : {top:'5px'}}>
          <PreArrow onClick={onPrev} />
          <NextArrow onClick={onNext} />
        </div>
      ) : null}
      {firstFilterArrary.length > 3 ? (
        <div className='arrows_2'>
          <PreArrow onClick={onPrev_2} disabled={disablePrev_2} />
          <NextArrow onClick={onNext_2} />
        </div>
      ) : null} */}
      {
        firstFilterArrary.length > 7 ? 
        (
          <div className='tmall-arrows'>
            <PreArrow onClick={onPrev} disabled={disablePrev_2}/>
            <NextArrow onClick={onNext} />
          </div>
        ) : null
      }
    </div>
  );
}

export default connect(({ login, global }) => ({
  userInfo: login.userInfo,
  isSaintsTheme: global.themeStatus
}))(React.memo(AreaFilter));
