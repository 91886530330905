import React, { useState, useEffect } from 'react';

import { Get } from 'utils/request';
import { getLanguage } from 'utils/utils';

function useCategory() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // 只是优化策略，避免闪烁
    try {
      const cacheData = JSON.parse(
        sessionStorage.getItem('category_bargain_all')
      );
      if (cacheData) {
        setData(cacheData);
      }
    } catch (error) {
      console.warn('get cache data error', error);
    }

    async function fetchData() {
      setLoading(true);
      try {
        const res = await Get(
          '/onedata_propool/product_pool/dict_category/list'
        );

        if (res.code === 2000 && res.data) {
          const dataArray = res.data?.content || [];
          // dataArray
          setData(dataArray);
          sessionStorage.setItem(
            'category_bargain_all',
            JSON.stringify(dataArray)
          );
        }
      } catch (error) {
        console.log('fetch error:', error);
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  return [data, loading];
}

export default useCategory;
