import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import { Button, Icon, Tooltip, Modal, Checkbox, Rate, message } from 'antd';
import { connect } from 'dva'
import { useDispatch } from 'react-redux'
import intl from 'react-intl-universal';
// import Lazyload from 'react-lazy-load';
import Lazyload from 'react-lazyload';
import qs from 'querystring';

import { dispatchEventForGuide } from '../../BeginnerGuide/util';
import styles from './Goods.less';
import copyToClipboard from 'utils/copyToClipboard';
import { Post } from '../../../utils/request';
import {
  replaceUrlToHttps,
  getAliExpressUrlV2,
  getLanguage,
  sendNoviceGuidanceInfo
} from 'utils/utils';
import deliveryIcon from '../../../assets/bargain/shipping_speed.svg';
import rewardIcon from '../../../assets/bargain/dollor.svg';
import newIcon from '../../../assets/bargain/new.png';
import copy from '../../../assets/bargain/copy.png';
import checked from '../../../assets/bargain/check_circle.png';
import { sendGoodsClickEvent, importButtonClickEvent } from '../util';
import { TitleIcon } from 'components/BargainProductTags';
import FindProductButton from 'components/FindProductButton';
import { truncateSync } from 'fs';
import { GuildeAction, LinkStoreAction } from 'actions';

import { CommonDataCollect } from 'utils/utils';
import tmallIcon from '../../../assets/tmallcon.svg';

const ignoreImgReg = /_200x200xz.jpg$/;

const addImageSizeParams = url => {
  if (ignoreImgReg.test(url)) {
    return `${replaceUrlToHttps(url)}_.webp`;
  }
  return `${replaceUrlToHttps(url)}_350x350.jpg_.webp`;
};
// 点击bargain按钮上报
// function bargainButtonClickEvent(productData, index) {
//   return {
//     event_category: 'product_bargain_button',
//     ...createClickEvent(productData, index)
//   };
// }

const disabledTextEnum = {
  1: 'bargain_disabled_by_order',
  2: 'counter_limit',
  3: 'bargain_disabled_by_bargained'
};

function getBargainDisabledInfo(orderCount, bargained, bargainCounter) {
  let disableStatus = 0;
  if (orderCount < 10) {
    disableStatus = 1;
  } else if (bargainCounter > 9) {
    disableStatus = 2;
  } else if (bargained) {
    disableStatus = 3;
  }
  const disabled = disableStatus > 0;
  return {
    disabled,
    disabledText: disabled
      ? intl.get(`bargain.${disabledTextEnum[disableStatus]}`)
      : ''
  };
}

const formatPrice = (minPrice, maxPrice, currencyState) => {
  try {
    const current_currency = currencyState[minPrice.currency]?.rates_value || 1
    const currentminprice = Math.round(minPrice.value / current_currency * 100) / 100
    const currentmaxprice = Math.round(maxPrice.value / current_currency * 100) / 100
    if ([minPrice.value, maxPrice.value].includes(0)) {
      return '-';
    }
    if (minPrice.value === maxPrice.value) {
      return `US $ ${currentminprice}`
    }
    return `US $ ${currentminprice}-${currentmaxprice}`;
  } catch (error) {
    return '-';
  }
};

const getDataPrice = (data,currencyState) => {
  // if(!data.price){
  //   console.log(data);
  // }
  if(data.maxPrice === data.minPrice){
    return `US $ ${Number(data.maxPrice) / 100}`
  }else{
    return `US $ ${Number(data.minPrice) / 100}-${Number(data.maxPrice) / 100}`
  }
};

const getDiscount = data => {
  const {
    min_activity_amount: minActivityPrice,
    min_amount: minPrice,
    is_activity: isActivity,
    discount
  } = data.price || {};
  if (discount && discount !== 0) {
    return parseInt(0 - discount) + '%';
  }
  if (isActivity && minActivityPrice && minPrice) {
    const priceGap = minActivityPrice.value - minPrice.value;
    const discountNum = parseInt(((priceGap / minPrice.value) * 10000) / 100);
    if (discountNum) {
      return discountNum + '%';
    }
  }
  return 0;
};

function Goods({
  // onBargain,
  data,
  onImportOk,
  simple,
  className,
  params:Params,
  // isReward,
  // isTenDaysDelivery,
  // isNewProduct,
  // hasBargainButton,
  // orderCount,
  // bargainCounter,
  // bargained,
  onPostClickEvent,
  index,
  displayGuide,
  noviceGuidanceInfo,
  currencyState
}) {
  const [copySuccess, setCopySuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [chooseCopyBtn, setChooseCopyBtn] = useState(false);
  const [failedModal, setFailedModal] = useState(false);

  const dispatch = useDispatch()

  const textAreaRef = useRef(null);
  const timerForDelayMsg = useRef(null);
  const dataUrl = useMemo(() => {
    return getAliExpressUrlV2(data.id);
  }, [data]);
  const dataPrice = useMemo(() => {
    return getDataPrice(data,currencyState);
  }, [data]);

  const discount = useMemo(() => {
    return getDiscount(data);
  }, [data]);

  const tags = useMemo(() => {
    return data.om_label || {};
  }, [data]);
  useEffect(() => {
    return () => {
      if (timerForDelayMsg.current) {
        clearTimeout(timerForDelayMsg.current);
      }
    };
  }, []);

  function handleCopy(e) {
    if (timerForDelayMsg.current) {
      clearTimeout(timerForDelayMsg.current);
    }

    const copyText = `https://${window.location.host}/app/tmallproduct/${data.productId}?appId=${data.appId} `;
    copyToClipboard(copyText);

    window.dataLayer.push({
      event: 'productBargainEvent',
      type: simple ? 'copyLinkFromSwitch' : 'copyLink',
      payload: copyText
    });

    e.target.focus();
    setCopySuccess(intl.get('bargain.copied'));

    timerForDelayMsg.current = setTimeout(() => {
      setCopySuccess('');
    }, 1000);

    setChooseCopyBtn(true);

    setTimeout(() => {
      setChooseCopyBtn(false);
    }, 2000);
  }


  const handleImport = useCallback(() => {
    CommonDataCollect({
      event_type:'tmall_findSupplier',
      action:'click_import'
    })
  
    window.dtag.set({
      event: 'click',
      module: 'tmall_find_import_click',
      action: 'tmall_find_import_click',
      custom_data:{
        search:Params.search,
        supply_product_id:data?.productId
      }
    })

    // const clickParams = importButtonClickEvent(data, index);
    // onPostClickEvent(clickParams);

    async function importRequest() {
      setLoading(true);
 
      const params = {
        supplier_type:4,
        product_id:data.productId,
        scene: 'dsers_page', // dsers_page || extension
        ref: 'bargain' // bargain || similar
      };
      if (data.token) {
        params.token = data.token;
      }
      try {
        const res = await Post('/prod/select', { data: params });
        if (res.code === 2000 && res.data) {
          setVisible(true);
          setShowIcon(true);
          window.dtag.set({
            event: 'click',
            module: 'tmall_find_import',
            action: 'tmall_find_import',
            custom_data:{
              search:Params.search,
              supply_product_id:data?.productId
            }
          })
          if(!localStorage.getItem('haveImportModalVisible')){
            localStorage.setItem('haveImportModalVisible',1) 
          }else{
            localStorage.setItem('haveImportModalVisible',localStorage.getItem('haveImportModalVisible') + 1)
          }
          dispatch({
            type: 'topBtn/notificationUnreadLength',
            payload: {
              cancelMessage: true,
            }
          });
        } else if (!localStorage.getItem('noReminder')) {
          // Modal.warning({
          //   title: intl.get('bargain.m_fail_title'),
          //   // icon: <Icon type="exclamation-circle" />,
          //   content: intl.get('bargain.m_fail_con'),
          //   okText: intl.get('bargain.m_fail_go'),
          //   className: 'window'
          // });
          // △ 解决两个弹窗冲突的问题  优先显示超限弹窗
          if (
            res.msg ==
            'The quantity of products you imported to DSers has reached the "Product limit" of your current subscription plan. Please upgrade your subscription to import more products.'
          ) {
            // window.EVENT.emit(
            //   GuildeAction.TurnPlan_Pop,
            //   `z-${Date.now()}`,
            //   true
            // );
            window.EVENT.emit(LinkStoreAction.CAM_MODAL,'ProductsLimit')
          } else {
            setFailedModal(true);
          }
        }
        if ([4090, 2000].includes(res.code)) {
          dispatchEventForGuide({
            type: 'bargain',
            payload: {
              category: 'IMPORT_SUCCESS'
            }
          });
        } else {
          dispatchEventForGuide({
            type: 'bargain',
            payload: {
              category: 'IMPORT_FAILED'
            }
          });
          if (
            res.msg ==
            'The quantity of products you imported to DSers has reached the "Product limit" of your current subscription plan. Please upgrade your subscription to import more products.'
          ) {
            // window.EVENT.emit(
            //   GuildeAction.TurnPlan_Pop,
            //   `z-${Date.now()}`,
            //   true
            // );
            window.EVENT.emit(LinkStoreAction.CAM_MODAL,'ProductsLimit')
          }
        }
        setLoading(false);
      } catch (error) {
        dispatchEventForGuide({
          type: 'bargain',
          payload: {
            category: 'IMPORT_FAILED'
          }
        });
      }
    }

    const { id } = data;
    const { user_id } = JSON.parse(localStorage.getItem('store'));


    importRequest();
  }, [onImportOk, data, onPostClickEvent, index, dataUrl]);

  const handleLinkClick = useCallback(() => {
    window.dtag.set({
      event: 'click',
      module: 'tmall_find_details',
      action: 'tmall_find_search_details',
      custom_data:{
        search:Params.search,
        supply_product_id:data?.productId
      }
    })
    // return sendGoodsClickEvent(data, pageInfo, index);
    const clickParams = sendGoodsClickEvent(data, index);
    onPostClickEvent(clickParams);
  }, [data, index, onPostClickEvent]);

  const isFirst = index === 0 && displayGuide;
  const cancel = () => {
    setVisible(false);
    localStorage.removeItem('haveImportModalVisible')
  };
  const toImportList = () => {
    onImportOk();
    localStorage.removeItem('haveImportModalVisible')
  };
  const setConfirmChecked = e => {
    const { checked } = e.target;
    if (checked) {
      localStorage.setItem('noReminder', true);
    } else {
      localStorage.removeItem('noReminder');
    }
  };

  const importDisabled =
    !window.DS_BEGINNER_GUIDE_ACTIVE && (data.imported || showIcon || loading);
  const showTestContent =
    window.DSERS_OPTIMIZER_TEST && localStorage.getItem('newCreated') === '0';

  const paramObj = {
    url: `https://www.dsers.com/app/tmallproduct/${data.productId}?appId=${data.appId}`,
    ref: 'find_suppliers',
    supplierType:"tmall"
  };
  const paramStr = qs.stringify(paramObj);
  const link = `/app/supplier_optimizer?${paramStr}`;

  const handleGoSupplier = () =>{
    window.dtag.set({
      event:'click',
      module:'tmall',
      action:'tmall_figure_search',
      custom_data:{
        'click_type': 'go_supplier_optimizer_findsupplier'
      }
    })
    window.open(link)
  }

  return (
    <>
      <div
        className={`${styles.goods} ${className} ${
          !simple && tags.is_ten_days ? styles.tendaysDelivery : ''
        }${isFirst ? styles.firstProduct : ''}
        `}
      >
        {tags.is_new ? <img className={styles.newMark} src={newIcon} /> : null}
        <div className={styles.imgCon}>
          <a
            href={`/app/tmallproduct/${data.productId}?appId=${data.appId}&search=${Params.search}`}
            onClick={()=>{CommonDataCollect({
              event_type:'tmall_findSupplier',
              action:'goto_product_detail'
            })
            window.dtag.set({
              event: 'click',
              module: 'tmall_find_details',
              action: 'tmall_find_search_details',
              custom_data:{
                search:Params.search,
                supply_product_id:data.productId
              }
            })
          
          }}
            target="_blank"
            rel="noopener noreferrer"
            track-type="productBargain"
            track-name="productUrl"
            // track-metadata-url={dataUrl}
            // onClick={handleLinkClick}
          >
            {index > 9 ? (
              <Lazyload height={230} scrollContainer="#BasicLayoutContentById">
                <img
                  className={styles.img}
                  src={data.mainImgUrl}
                  alt=""
                />
              </Lazyload>
            ) : (
              <img
                className={styles.img}
                src={data.mainImgUrl}
                alt=""
              />
            )}
          </a>
        </div>
        <Tooltip
          title={
            importDisabled
              ? intl.get('suppliers.importBtnEd')
              : intl.get('suppliers.importBtn')
          }
        >
          <div
            className={styles.listAlt}
            style={{
              cursor: importDisabled ? 'not-allowed' : 'pointer',
              backgroundColor: importDisabled ? '#e0e0e0' : '#ff8f00'
            }}
            onClick={!importDisabled && handleImport}
          >
            <i class="material-icons">list_alt</i>
          </div>
        </Tooltip>
        <Tooltip title={intl.get('supplier_optimizer.title')}>
          <div className={styles.searchBtn}>
            <a onClick={handleGoSupplier} target="_blank">
              <i class="material-icons notranslate">search</i>
            </a>
          </div>
        </Tooltip>
        <div className={styles.goodsCon}>
          <div className={styles.goodsInfo}>
            <a
              href={`/app/tmallproduct/${data.productId}?appId=${data.appId}&search=${Params.search}`}
              onClick={()=>{CommonDataCollect({
                event_type:'tmall_findSupplier',
                action:'goto_product_detail'
              })
              window.dtag.set({
                event: 'click',
                module: 'tmall_find_details',
                action: 'tmall_find_search_details',
                custom_data:{
                  search:Params.search,
                  supply_product_id:data.productId
                }
              })
            
            }}
              target="_blank"
              rel="noopener noreferrer"
              track-type="productBargain"
              track-name="productUrl"
              track-metadata-url={dataUrl}
              // onClick={handleLinkClick}
            >
              <span
                className={styles.goodsTitle}
                ref={textAreaRef}
                title={data.title}
                // data-url={dataUrl}
              >
               <img src={tmallIcon} alt="" />
                {data.title}
              </span>
            </a>
            <span className={styles.copy} onClick={handleCopy}>
              <Tooltip title={!copySuccess ? intl.get('bargain.link_copy') : copySuccess}>
                {
                  !copySuccess ? 
                  <img src={copy} alt="" /> :
                  <img src={checked} alt="" />
                }
              </Tooltip>
            </span>
            <span className={styles.copyTip}>{copySuccess}</span>
          </div>
          {!simple ? (
            <>
              <div className={styles.descriptionContainer}>
                <div className={styles.cost}>
                  <span className={styles.highlight}>
                    {dataPrice}
                  </span>
                </div>
                <div className={styles.usOnly}>{intl.get('bargain.onlyFor')}</div>
                {tags.supplier === 'dsers' && (
                  <div className={styles.description}>
                    {tags.supplier === 'dsers' ? (
                      <span>{intl.get('bargain.filter.processing')}</span>
                    ) : null}
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
      {!localStorage.getItem('noReminder') && localStorage.getItem('haveImportModalVisible') === '1' && (
        <Modal
          visible={visible}
          width={560}
          footer={false}
          className={styles.window}
          onCancel={() => {
            setVisible(false)
            localStorage.removeItem('haveImportModalVisible')
          }}
        >
          <div className={styles.alertWindow}>
            <h3 className={styles.title}>{intl.get('bargain.m_title')}</h3>
            <p className={styles.dec}>{intl.get('bargain.m_con')}</p>
            <div className={styles.reminder}>
              <Checkbox
                className={styles.checkbox}
                id="alertCheckbox"
                onChange={e => {
                  setConfirmChecked(e);
                }}
              >
                {intl.get('bargain.m_reminder')}
              </Checkbox>
              {/* <Tooltip
                title={intl.get('bargain.reminder_help')}
                arrowPointAtCenter="false"
                placement="bottomLeft"
              >
                <span className={styles.help} />
              </Tooltip> */}
            </div>
            <div className={styles.btnFooter}>
              <Button
                type="primary"
                onClick={toImportList}
                className={styles.goBtn}
              >
                {intl.get('bargain.m_btn_go')}
              </Button>
              <Button onClick={cancel} className={styles.stayBtn}>
                {intl.get('bargain.m_btn_stay')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {failedModal && (
        <Modal
          visible={failedModal}
          width={560}
          footer={false}
          className={styles.window}
          onCancel={() => setFailedModal(false)}
        >
          <div className={styles.alertWindow}>
            <h3 className={styles.title}>{intl.get('bargain.m_fail_title')}</h3>
            <p className={styles.dec}>{intl.get('bargain.m_fail_con')}</p>
            <div className={styles.btnFooter}>
              <Button
                onClick={() => {
                  setFailedModal(false);
                }}
                className={styles.filedOkBtn}
              >
                {intl.get('bargain.m_fail_go')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

Goods.defaultProps = {
  simple: false,
  className: null,
  isReward: false
};

// export default React.memo(Goods);
export default connect(({ user, global }) => ({
  noviceGuidanceInfo:user?.noviceGuidanceInfo,
  currencyState:global.currencyState
}))(Goods)