import React, { useEffect, useState } from 'react';
import Bargain from 'routes/Bargain';
import TmallBargain from 'routes/TmallBargain';
import { Tabs } from 'antd';
import { connect } from 'dva';
import ScrollControl from 'components/ScrollControl';
import styles from './index.less';
import { DSERS_IMG_HOST } from 'utils/constants';
import classNames from 'classnames';
import Media from 'react-media';
import tmallTop from 'assets/img/tmallTheme/tmallHeaderLogo.png';

const { TabPane } = Tabs;

function Index({isMobile, isSaintsTheme}) {
  const [type, setType] = useState('tmall');

  const handleCategory = type => {
    return () => {
      setType(type);
    };
  };

  useEffect(() => {
    if(isMobile){
      return
    }

    let camtitle=document.querySelector('#camtitleIds')
    if(camtitle){
      const cateId=document.getElementById('categoryId')
      cateId && cateId?.style?(
        cateId.style.marginTop='87px'
      ):null

      const byId=document.getElementById('BasicLayoutContentById')
      byId && cateId?.style?(

        byId.style.marginTop='112px'
      ):null
    const byClass=document.getElementById('byClass')
    byClass && cateId?.style?(
      byClass.style.marginTop='48px',
      byClass.style.height='calc(100% - 64px - 48px)'
    ):null
    }
    console.log(isSaintsTheme,'1111')
    if(isSaintsTheme){
      let layout = document.querySelector('.ant-layout')
      layout.classList.add('saintsThemeLayout')
  
      let sider = document.querySelector('.ant-layout-sider')
      sider.classList.add('saintsThemeSider')
  
      let header = document.querySelector('.ant-layout-header')
      header.classList.add('saintsThemeHeader')
  
      let main = document.querySelector('.ant-layout-content')
      main.classList.add('saintsThemeMain')
    }else{
      let layout = document.querySelector('.ant-layout')
      layout.style.background = null;
      layout.classList.remove('saintsThemeLayout')

      let sider = document.querySelector('.ant-layout-sider')
      sider.classList.remove('saintsThemeSider')

      let header = document.querySelector('.ant-layout-header')
      header.classList.remove('saintsThemeHeader')

      let main = document.querySelector('.ant-layout-content')
      main.classList.remove('saintsThemeMain')
    }

    return function () {
      let layout = document.querySelector('.ant-layout')
      layout.style.background = null;
      layout.classList.remove('saintsThemeLayout')

      let sider = document.querySelector('.ant-layout-sider')
      sider.classList.remove('saintsThemeSider')

      let header = document.querySelector('.ant-layout-header')
      header.classList.remove('saintsThemeHeader')

      let main = document.querySelector('.ant-layout-content')
      main.classList.remove('saintsThemeMain')
    }

  }, [isSaintsTheme])

  return (
    <div className={`${styles.tabBar} ${isSaintsTheme ? styles.themeClass : null}`}>
      {window.TMALL_USER ? (
        <div className={styles.tabTop}>
          <div>
            <div className={styles.tabSwitch}>
              <div className={styles.category}  id="categoryId">
                <span
                  className={classNames(
                    type === 'tmall' ? styles.active : null,
                    styles.tmallTabTitle
                  )}
                  onClick={handleCategory('tmall')}
                >
                  Tmall
                  {/* <img width="40" src={`${DSERS_IMG_HOST}/`} /> */}
                  {<img width="40" src={`${DSERS_IMG_HOST}/shopify/activity/tmall_cold_startup/221019/tmall_productpool_tab_icon.gif`} />}
                </span>
                <span
                  className={type === 'ali' ? styles.active : null}
                  onClick={handleCategory('ali')}
                >
                  AliExpress
                </span>
              </div>
              <div
                className={
                  type === 'tmall' ? styles.itemLine : styles.itemLine_ali
                }
              ></div>
              <div className={styles.line}></div>
            </div>
          </div>
          <div>
            {type === 'tmall' ? (
              <TmallBargain></TmallBargain>
            ) : (
              <Bargain></Bargain>
            )}
          </div>
        </div>
      ) : (
        <Bargain></Bargain>
      )}
      {
        isSaintsTheme ? (
          <>
            <img className={styles.christmasMoon} src={tmallTop} alt="" />
          </>
        ) : null
      }
    </div>
  );
}

export default connect(({ global }) => ({
  isSaintsTheme : global.themeStatus
}))(props => (
  <Media
    queries={{
      small: '(max-width: 599px)',
    }}
  >
    {matches => (
      <Index
        {...props}
        isMobile={matches.small}
      />
    )}
  </Media>
));