import React from 'react';
import intl from 'react-intl-universal';

import styles from './Sorter.less';

const ArrowSorterItem = ({
  title,
  valueUp,
  valueDown,
  currentValue,
  onChange,
  reverse
}) => {
  const activeUp = currentValue === valueUp;
  const activeDown = currentValue === valueDown;
  const active = activeUp || activeDown;
  const handleClick = () => {
    if (reverse) {
      const value = activeDown ? valueUp : valueDown;
      onChange(value);
      return;
    }
    const value = activeUp ? valueDown : valueUp;
    onChange(value);
  };
  return (
    <div
      onClick={handleClick}
      className={`${styles.sorterItem} ${active ? styles.active : ''}`}
      role="button"
      tabIndex="-1"
    >
      <span className={styles.sorterName}>{title}</span>
      <div className={styles.arrowContainer}>
        <span
          className={`${styles.arrowUp} ${activeUp ? styles.activeItem : ''}`}
        />
        <span
          className={`${styles.arrowDown} ${
            activeDown ? styles.activeItem : ''
          }`}
        />
      </div>
    </div>
  );
};

const SorterItem = ({ title, value, currentValue, onChange }) => {
  const active = value === currentValue;
  const handleClick = () => {
    if (value === currentValue) {
      return;
    }
    onChange(value);
  };
  return (
    <div
      onClick={handleClick}
      className={`${styles.sorterItem} ${active ? styles.active : ''}`}
      role="button"
      tabIndex="-1"
    >
      <span className={styles.sorterName}>{title}</span>
    </div>
  );
};

const sorters = [
  {
    title: intl.get('bargain.sorter.default'),
    type: 'single',
    value: ''
  },
  {
    title: intl.get('bargain.sorter.orders'),
    type: 'single',
    value: '-orders'
    // valueUp: '+orders',
    // valueDown: '-orders',
    // reverse: true
  },
  {
    title: intl.get('bargain.sorter.new'),
    type: 'single',
    value: '-new'
  },
  {
    title: intl.get('bargain.sorter.price'),
    type: 'arrow',
    valueUp: '+price',
    valueDown: '-price'
  }
];

const Sorter = ({ currentSorter, onChange }) => {
  return (
    <div className={styles.sorterContainer}>
      <span className={styles.sorterTitle}>{`${intl.get('supplier_optimizer.sort_by')} :`}</span>
      <div className={styles.sorterInnerContainer}>
        {sorters.map(item => {
          const Component =
            item.type !== 'arrow' ? SorterItem : ArrowSorterItem;
          return (
            <Component
              key={item.title}
              onChange={onChange}
              title={item.title}
              currentValue={currentSorter}
              value={item.value}
              valueUp={item.valueUp}
              valueDown={item.valueDown}
              reverse={item.reverse}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Sorter;
