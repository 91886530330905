export const initialState = {
  pagination: {
    total: 0,
    current: 1,
    pageSize: 60
  },
  bargainCounter: 0,
  loading: false,
  productType: '',
  search: '',
  data: [],
  orderCount: 0,
  bargainedList: [],
  dataSource: 'dsers'
};

export function reducer(state, action) {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: action.payload
      };
    case 'setData':
      return {
        ...state,
        data: action.payload
      };
    case 'setPaginationData':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    case 'setBargainCounter':
      return {
        ...state,
        bargainCounter: action.payload
      };
    case 'increaseBargainCounter':
      return {
        ...state,
        bargainCounter: state.bargainCounter + 1
      };
    case 'setOrderCount':
      return {
        ...state,
        orderCount: action.payload
      };
    case 'setBargainedProducts':
      return {
        ...state,
        bargainedList: action.payload
      };
    case 'setSearch':
      return {
        ...state,
        search: action.payload
      };
    case 'setSource':
      return {
        ...state,
        dataSource: action.payload
      };
    case 'reset':
      return { ...initialState };
    default:
      throw new Error();
  }
}

export const initFilterParams = {
  shippingFrom: '',
  reward_status: 0,
  delivery_days_status: 0,
  free_return: 0,
  category: [],
  partition: [],
  sort: 'DEFAULT',
  min_price: '',
  max_price: '',
  imgUrl: ''
};

export function filterReducer(state, action) {
  switch (action.type) {
    case 'setShipFrom':
      return {
        ...state,
        shippingFrom: action.payload === 'all' ? '' : action.payload
      };
    case 'setCategory':
      return {
        ...state,
        category: action.payload === 'All' ? '' : action.payload
      };
    case 'setReward':
      return {
        ...state,
        reward_status: action.payload
      };
    case 'setShipping':
      return {
        ...state,
        delivery_days_status: action.payload
      };
    case 'setPartition':
      return {
        ...state,
        partition: action.payload
      };
    case 'setSorter':
      return {
        ...state,
        sort: action.payload
      };
    case 'setFreeReturn':
      return {
        ...state,
        free_return: action.payload
      };
    case 'setPrice':
      return {
        ...state,
        min_price: action.payload.min,
        max_price: action.payload.max
      };
    case 'setImgUrl':
      return {
        ...state,
        imgUrl:action.payload

      }
    default:
      throw new Error();
  }
}
