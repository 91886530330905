import React, { useState, useEffect, useRef } from 'react';
import { Button, Input } from 'antd';
import intl from 'react-intl-universal';

import NumericInput from '../../NumericInput';
import styles from './PriceInput.less';

export const limitNumByPric = (num, pri = 2) => {
  const numString = String(num);
  // numString = scientificToString(numString)
  if (numString === '') {
    return '';
  }
  const nums = numString.split('.');
  let float = nums[1];
  let intNum = nums[0];
  if (intNum) intNum = intNum.replace(/^0+/, '') || '0';
  if (float && float.length > pri) {
    float = float.slice(0, pri);
    return `${nums[0]}.${float}`;
  }
  if (float === '') {
    return `${intNum}.`;
  }
  if (float) {
    return `${intNum || '0'}.${float}`;
  }
  return intNum;
};

const PriceInput = ({ currentMin, currentMax, onChange, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const minRef = useRef(null);
  const maxRef = useRef(null);
  const [max, setMax] = useState('');
  const [min, setMin] = useState('');
  useEffect(() => {
    const clickFn = e => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (path && path.length) {
        for (let i = 0; i < path.length; i += 1) {
          if (typeof path[i].getAttribute !== 'function') {
            continue;
          }
          if (path[i].getAttribute('customtag') === 'price-input') {
            return;
          }
        }
      }
      // console.log('click')
      setOpen(false);
    };
    document.addEventListener('click', clickFn);
    return () => {
      document.removeEventListener('click', clickFn);
    };
  }, []);

  const initValues = () => {
    setMin(currentMin);
    setMax(currentMax);
  };

  const minFocus = () => {
    setOpen(true);
    initValues();
    // console.log(minRef.current.input);
    if (minRef.current.input?.focus) {
      setTimeout(() => {
        minRef.current.input.focus();
      });
    }
  };

  const maxFocus = () => {
    setOpen(true);
    initValues();
    // console.log(maxRef.current);
    if (maxRef.current.input?.focus) {
      // console.log(maxRef.current);
      setTimeout(() => {
        maxRef.current.input?.focus();
      }, 200);
    }
  };

  const maxChange = e => {
    // console.log(e.target.value);
    const value = e.target.value.trim();
    const valueNum = Number(value);
    if (isNaN(valueNum)) {
      return;
    }
    setMax(limitNumByPric(value));
  };
  // console.log(max)
  const minChange = e => {
    const value = e.target.value.trim();
    const valueNum = Number(value);
    if (isNaN(valueNum)) {
      return;
    }
    setMin(limitNumByPric(value));
  };

  const saveHandler = () => {
    setOpen(false);
    if (min === currentMin && max === currentMax) {
      return;
    }
    let newMax = max;
    let newMin = min;
    if (Number(max) < Number(min) && max !== '') {
      [newMin, newMax] = [max, min];
    }
    onChange({
      max: newMax,
      min: newMin
    });
  };

  return (
    <div className={styles.priceInputContainer} customtag="price-input">
      <span className={styles.priceTitle}>
        {intl.get('bargain.sorter.price')}:
      </span>
      <Input
        onFocus={minFocus}
        type="tel"
        placeholder="min"
        className={styles.priceInput}
        value={currentMin}
        disabled={disabled}
      />
      &nbsp;-&nbsp;
      <Input
        className={styles.priceInput}
        placeholder="max"
        type="tel"
        onFocus={maxFocus}
        value={currentMax}
        disabled={disabled}
      />
      <div className={`${styles.bg} ${open ? styles.open : ''}`}>
        <span className={styles.priceTitle}>
          {intl.get('bargain.sorter.price')}:
        </span>
        <Input
          ref={minRef}
          type="tel"
          placeholder="min"
          onChange={minChange}
          className={styles.priceInput}
          value={min}
        />
        &nbsp;-&nbsp;
        <Input
          ref={maxRef}
          className={styles.priceInput}
          onChange={maxChange}
          placeholder="max"
          type="tel"
          value={max}
        />
        <Button onClick={saveHandler} type="primary" className={styles.saveBtn}>
          OK
        </Button>
      </div>
    </div>
  );
};

export default PriceInput;
