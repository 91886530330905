import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { DSERS_IMG_HOST } from 'utils/constants';
import { CommonDataCollect } from 'utils/utils';
import { Get } from 'utils/request';

import styles from './TmallBanner.less';

const TmallBanner = ({ lang, active, onChange, bannerData }) => {


    const handleClickBanner = ({ lang }) => {
        onChange()
    };

    return (
        <div onClick={handleClickBanner} className={classNames(`${styles.container}`, { [styles.active]: active })}>
            <img src={bannerData?.img_url} alt="" />
        </div>
    );
};


export default TmallBanner;
