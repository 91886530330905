import { Icon,Tooltip } from 'antd';
import React from 'react';
import { routerRedux } from 'dva/router';
import intl from 'react-intl-universal';
import qs from 'querystring';

import store from '../../index';
import styles from './index.less';
import { getLanguage } from 'utils/utils';
import { getDisplaySupplierOptimizer } from 'common/checkUserForSupplierOptimizer';
import searchImg from '../../assets/svg/search_active.svg';

const { dispatch } = store;

const testUrl = url => {
  if (!url) {
    return false;
  }
  return /(http|https):\/\//.test(url);
};

function FindProductButton({ id, image, offset, hidden, pageRef, isFirst }) {
  const paramObj = {
    url: image,
    ref: pageRef,
    id,
    store_id: localStorage.getItem('storeId')
  };
  const paramStr = qs.stringify(paramObj);
  const link = `/app/supplier_optimizer?${paramStr}`;
  // console.log(offset)

  const imageInvalid = !testUrl(image) || hidden;

  const hasAccess = getDisplaySupplierOptimizer();
  const showTestContent =
    window.DSERS_OPTIMIZER_TEST && localStorage.getItem('newCreated') === '0';
  // const clickHandler = () => {
  //   dispatch(routerRedux.push(`/supplier_optimizer?url=${image}`));
  // };
  return hasAccess ? (
    <a
      className={styles.findProductButton}
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      // onClick={clickHandler}
      // role="button"
      // tabIndex="-1"
      style={{
        marginTop: offset ? '0px' : '0',
        display: imageInvalid ? 'none' : 'flex'
      }}
    >
      <Tooltip title={intl.get('supplier_optimizer.title')}>
        <span className={styles.findIcon}>
          {/* <Icon type="search" /> */}
          {/* <img src={searchImg} width="12" height="12" /> */}
          <i class="material-icons notranslate">search</i>
        </span>
      </Tooltip>
      {/* <span
        className={`${
          isFirst
            ? `${styles.firstText} ${getLanguage()}`
            : `${styles.text} ${getLanguage()}`
        }`}
      >
        {showTestContent
          ? intl.get('supplier_optimizer.title_test')
          : intl.get('supplier_optimizer.title')}
      </span> */}
    </a>
  ) : null;
}

export default React.memo(FindProductButton);
