import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import { Dropdown, Input, Cascader, Button } from 'antd';
import debounce from 'lodash/debounce';

import { CameraButton } from 'routes/SupplierOptimizer/components/Buttons';
import { getBaseLayoutPopupContainer } from 'utils/utils';
import styles from './SearchProduct.less';
import useConfigData from '../useConfigData';
import aeLogo from '../../../assets/bargain/ae_logo.png';
import dropDown from '../../../assets/banner/dropdown.png';
import searchImg from '../../../assets/svg/search.svg';
import dropDownImg from '../../../assets/img/find-dropdown.png';
import Category from './Category';
import { bargainEventBus } from '../util';

const categoryNameKeys = {
  'pt-PT': 'name_pt',
  'en-US': 'name_en',
  'fr-FR': 'name_fr'
};

function SearchProduct({
  handleSearch,
  category,
  handleFilterChange,
  dispatch,
  currentCategory,
  openUploadModal,
  currentPartion
}) {
  const inputRef = useRef(null);
  const [hotWords] = useConfigData('hot_word_bargain');
  const [activeWord, setActiveWord] = useState('');
  const [visible, setCategoryVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [hotStatus, setHotStatus] = useState(false);
  // const [initCategory, setInitCategory] = useState(false);
  // const current = currentCategory;
  const formatedCurrentCatogery = currentCategory?.map(item => item.code);
  // console.log(formatedCatogery)
  const handleWordClick = value => {
    handleSearch(value, true);
    setActiveWord(value);
    setHotStatus(true);
    if (inputRef.current) {
      try {
        inputRef.current.state.value = value;
        inputRef.current.input.setValue(value);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleInputChange = useMemo(() => {
    // return debounce(e => {
    //   const searchValue = e.target.value;
    //   console.log(searchValue);
    //   handleSearch(searchValue, false);
    //   if (activeWord) {
    //     setActiveWord('');
    //   }
    // }, 1000);
  }, [activeWord, handleSearch]);

  const handleCategoryChange = (value, selectedOptions) => {
    dispatch({ type: 'setCategory', payload: selectedOptions });
    handleFilterChange('type');
    if(currentPartion[0]?.type === 2){
      dispatch({ type: 'setPartition', payload: [] });
    }
  };

  const handleFind = () => {
    setPopupVisible(!Boolean(popupVisible))
  }

  addEventListener('click', (e) => {
    console.log(e.target.id, 'target')
    if (e.target.id !== 'findDropDown') {
      setPopupVisible(false);
    }
  })

  useEffect(() => {
    const clearInput = () => {
      handleWordClick('');
    };
    bargainEventBus.on('clearSearchInput', clearInput);
    return () => {
      bargainEventBus.removeListener('clearSearchInput', clearInput);
    };
  }, []);

  const searchProducthandler = debounce(()=>{
    console.log(inputRef.current.state.value);
      handleSearch(inputRef.current.state.value, false);
      if (activeWord) {
        setActiveWord('');
      }
  },500)
  // console.log(currentCategory)
  const categoryNameKey = categoryNameKeys[intl.getInitOptions().currentLocale] || 'name_en';

  return (
    <div className={styles.searchContainer}>
      <div className={styles.logoContainer}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <img src={aeLogo} />
          <div className={styles.findDropDown} onClick={handleFind}>
            <img id="findDropDown" src={dropDownImg} />
            <Cascader
              popupVisible={popupVisible}
              expandTrigger="hover"
              fieldNames={{ label: categoryNameKey, value: 'code' }}
              options={category}
              popupClassName={styles.categorySelector}
              value={formatedCurrentCatogery}
              changeOnSelect
              onChange={handleCategoryChange}
              popupPlacement="bottomLeft"
              onPopupVisibleChange={setCategoryVisible}
            >
              <div
                className={`${styles.categoryIcon} ${visible ? styles.active : ''
                  }`}
                style={{ visibility: 'hidden' }}
              >
                <div className={styles.categoryIconLeft}>
                  <div className={styles.iconLine} />
                  <div className={styles.iconLine} />
                  <div className={styles.iconLine} />
                </div>
                <div className={styles.categoryIconRight} />
              </div>
            </Cascader>
          </div>
        </div>
        {intl.get('bargain.logo_text')}
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputInnerContainer} style={{position:'relative'}}>
          <Input
            allowClear
            size="large"
            placeholder={intl.get('bargain.search_p')}
            maxLength={1000}
            ref={inputRef}
            className="bargain-search-input"
            onChange={e => {
              console.log(e.target.value, 'valvalvalval-=-=-=')
              e.persist();
              handleInputChange(e);
            }}
            suffix={
              inputRef.current?.state?.value ?
              <i class="material-icons notranslate"
                onClick={() => {
                  inputRef.current.state.value = '';
                  handleSearch('', false)
                  setHotStatus(false)
                }}
                style={{ fontSize: '20px', cursor: 'pointer', position: 'relative', left: '-55px' }} >cancel</i>
                :
                null
            }
            addonBefore={
              <CameraButton
                onClick={openUploadModal}
                className={styles.aliThemeButton}
              />
            }
          />
          {/* <div className={styles.findCancle} onClick={() => { setSearchVal('') }}><i class="material-icons">cancel</i></div> */}
          <Button className={styles.findOkBtn} onClick={searchProducthandler}><i class="material-icons">search</i></Button>
        </div>
        {/* <div className={styles.hotWordContainer}>
          {hotWords.map((item, index) => {
            return (
              <span
                onClick={() => handleWordClick(item.code)}
                key={index}
                role="button"
                tabIndex={-1}
                className={`${styles.hotWord} ${activeWord === item.code && hotStatus ? styles.active : ''
                  }`}
              >
                {item.code}
              </span>
            );
          })}
        </div> */}
      </div>
    </div>
  );
}

export default React.memo(SearchProduct);
