import React from 'react';
import { Dropdown } from 'antd';

import styles from './Category.less';

const CategoryItem = ({ rootCategory, onChange, currentCategory }) => {
  const langMap = {
    'en-US': 'name_en',
    'fr-FR': 'name_fr',
    'pt-PT': 'name_pt'
  };

  const lang = localStorage.getItem('lang') || 'en-US';
  const langName = langMap[lang];

  const handleClick = () => {
    if (rootCategory.code === currentCategory) {
      return;
    }
    onChange(rootCategory.code);
  };

  const active = currentCategory === rootCategory.code;
  return !rootCategory.children ? (
    <div
      role="button"
      tabIndex="-1"
      onClick={handleClick}
      title={rootCategory[langName]}
      className={`${styles.categoryRootItem} ${active ? styles.active : ''}`}
    >
      <span>{rootCategory[langName]}</span>
    </div>
  ) : (
    <div className={styles.categoryChildren}>
      <Dropdown
        overlay={
          <Category
            onChange={onChange}
            currentCategory={currentCategory}
            category={rootCategory.children}
          />
        }
        overlayClassName={styles.dropdownOverlay}
      >
        <div
          role="button"
          tabIndex="-1"
          onClick={handleClick}
          title={rootCategory[langName]}
          className={`${styles.categoryRootItem} ${
            active ? styles.active : ''
          }`}
        >
          <span>{rootCategory[langName]}</span>
        </div>
      </Dropdown>
    </div>
  );
};

const Category = ({ category, onChange, currentCategory }) => {
  return (
    <div className={styles.categoryDropdown}>
      {category.map(rootCategory => {
        return (
          <CategoryItem
            onChange={onChange}
            key={rootCategory.code}
            rootCategory={rootCategory}
            currentCategory={currentCategory}
          />
        );
      })}
    </div>
  );
};

export default Category;
