import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Alert } from 'antd';
import intl from 'react-intl-universal';

import styles from './index.less';
import { InstallExtAlert } from './InstallExtAlert';
import { InfoAlert } from 'components/Banner/OptimizerInfoTip';

const alertMap = {
  searchByImgFailed: () => {
    return {
      message: intl.get('bargain.img_search_failed_tip'),
      type: 'info',
      closable: true,
      showIcon: true
    };
  },
  searchByImgSuccess: exitImgSearch => {
    return {
      message: (
        <span>
          {intl.get('bargain.img_search_success_tip_1')}
          <span
            className={styles.hereLink}
            role="link"
            tabIndex="-1"
            onClick={exitImgSearch}
          >
            &nbsp;{intl.get('bargain.here')}&nbsp;
          </span>
          {intl.get('bargain.img_search_success_tip_2')}
        </span>
      ),
      type: 'info',
      closable: true,
      showIcon: true
    };
  }
};

const SearchAlert = ({
  data,
  dispatch,
  searchTip,
  filterDispatch,
  setTip,
  handleFilterChange
}) => {
  const visible = !!searchTip;

  const installPluginTip = searchTip === 'installExtension';

  const handleClose = useCallback(() => {
    setTip(false);
  }, [setTip]);

  const exitImgSearch = useCallback(() => {
    dispatch({
      type: 'setSource',
      payload: 'dsers'
    });
    handleFilterChange('category');
    handleClose();
  }, [handleFilterChange, dispatch, handleClose]);

  const alertConfigs = useMemo(() => {
    if (!searchTip || !alertMap[searchTip]) {
      return {};
    }
    return alertMap[searchTip](exitImgSearch);
  }, [searchTip, exitImgSearch]);
  // console.log(alertConfigs);
  const showInfoTip = searchTip === 'searchByImgSuccess';

  return (
    <div className={styles.alertContainer}>
      {visible && !installPluginTip ? (
        <Alert afterClose={handleClose} {...alertConfigs} />
      ) : null}
      {visible && showInfoTip ? (
        <InfoAlert className={styles.infoAlert} />
      ) : null}
      {installPluginTip ? <InstallExtAlert /> : null}
    </div>
  );
};

export default React.memo(SearchAlert);
