import React, { useEffect, useState } from 'react';
// IntersectionObserver Polyfill
import 'intersection-observer';

const initDataList = [];
const initCallback = () => {}
function useListExposureCounter(
  cssSelecotr = '',
  dataList = initDataList,
  callback = initCallback,
  cols = 4,
  exposureTime = 500,
  threshold = 0.75,
  ...deps
) {
  useEffect(() => {
    if (!dataList.length || !cssSelecotr) {
      return () => {};
    }
    const elements = document.querySelectorAll(cssSelecotr);
    if (!elements || !elements.length || !dataList.length || !cssSelecotr) {
      return () => {};
    }
    const observedArr = [];
    // has import polyfill
    // eslint-disable-next-line compat/compat
    const observer = new IntersectionObserver(
      actionList => {
        actionList.forEach(action => {
          // 监听的元素可见性发生变化 找到发生变化的数据
          const observedObj = observedArr.find(
            item => item.rowStartElement === action.target
          );
          if (observedObj) {
            // 可见性没变化或已曝光过
            if (
              action.isIntersecting === observedObj.visible ||
              observedObj.exposured
            ) {
              return false;
            }
            // 元素可见性发生改变 由不可见到可见时设置定时器
            // 清除旧的计时器
            clearTimeout(observedObj.timeout);
            if (action.isIntersecting) {
              // 设置新计时器
              const timeoutTag = setTimeout(() => {
                if (observedObj.timeout === timeoutTag) {
                  callback(observedObj);
                  observedObj.exposured = true;
                  // console.log('商品曝光了', observedObj);
                }
              }, exposureTime);
              observedObj.timeout = timeoutTag;
            }
            observedObj.visible = action.isIntersecting;
          }
          return false;
        });
      },
      {
        threshold
      }
    );
    if (!observer) {
      return () => {};
    }
    for (let i = 0; i < elements.length; i += 1) {
      if (i % cols === 0) {
        observer.observe(elements[i]);
        observedArr.push({
          rowStartElement: elements[i],
          rowData: [...dataList.slice(i, i + cols)],
          visible: false,
          position: i,
          exposured: false
        });
      }
    }

    return () => {
      observer.disconnect();
      observedArr.forEach(observedObj => {
        clearTimeout(observedObj.timeout);
      });
    };
  }, [cssSelecotr, cols, dataList, exposureTime, threshold, callback, ...deps]);
}

export default useListExposureCounter;
