import { Select, Breadcrumb, Checkbox } from 'antd';
import React, { useMemo, useCallback, useState } from 'react';
import intl from 'react-intl-universal';
import FilterCard from './components/FilterCard';
import _ from 'lodash';
import styles from './index.less';

import AreaFilter from './components/AreaFilter';
import useConfigData from '../../useConfigData';
import useOnlineNotice from 'hooks/useOnlineNotice';
import { getBaseLayoutPopupContainer } from 'utils/utils';
import PriceInput from './components/PriceInput';
import Sorter from './components/Sorter';
import { relative } from 'path';

import icon from 'assets/supplier_optimizer/search_null_tip_icon.png'

const { Option } = Select;

// filter对应store中的key
const filterStoreKeyList = [
  'delivery_days_status',
  'free_return',
  'reward_status'
];
const keyMap = {
  Shipping: 'delivery_days_status',
  FreeReturn: 'free_return',
  Reward: 'reward_status'
};

// const shipFromCountries = [
//   {
//     label: 'All',
//     value: ''
//   },
//   {
//     label: 'China',
//     value: 'CN'
//   },
//   {
//     label: 'United States',
//     value: 'US'
//   },
//   {
//     label: 'Turkey',
//     value: 'TK'
//   }
// ];

const checkboxFilters = [
  {
    key: 'Reward',
    label: intl.get('bargain.filter.processing')
  },
  // {
  //   key: 'Shipping',
  //   label: intl.get('bargain.tag.delivery')
  // },
  {
    key: 'FreeReturn',
    label: intl.get('bargain.filter.free_return')
  }
];

const categoryNameKeys = {
  'pt-PT': 'name_pt',
  'en-US': 'name_en',
  'fr-FR': 'name_fr'
};

function FilterAndSorter({
  dispatch,
  onChange,
  filterParams,
  category,
  dataSource,
  showSearchEmptyTip,
  emptyTipType
}) {
  // 分区数据
  const areaFilters = useConfigData()[2];
  // 发货地数据
  const [shipFromCountries] = useConfigData('ship_from_bargain');

  const [shipValue, setShipValue] = useState(
    intl.get('bargain.filter.ship_from')
  );
  //主分区阴影设置
  const [showShadow, setShowShadow] = useState('');
  const [areaArray, setAreaArray] = useState([]);
  const [areaSecond, setAreaSecond] = useState();
  const [secondAreaList, setSecondAreaList] = useState();
  // 点击主分区
  const handleClick = item => {
    let value = [];
    setSecondAreaList(item.subsets);
    if (_.findIndex(currentArea, item) !== -1) {
      value = [];
      setSecondAreaList();
    } else {
      value = [item];
    }
    setShowShadow(value);
    handlePartitionChange(value);

    return true;
  };

  //点击副专区
  const handleClick_second = item => {
    let value = currentArea;
    if (!currentArea.length) {
      value = [item];
    } else if (
      item.code === 'Customized Package' &&
      _.findIndex(currentArea, item) === -1
    ) {
      value = [item];
      setSecondAreaList([]);
    } else if (
      currentArea[0].type === 1 &&
      _.findIndex(currentArea, item) === -1
    ) {
      currentArea[1] = item;
      value = currentArea;
    } else if (
      currentArea[0].type === 1 &&
      _.findIndex(currentArea, item) !== -1
    ) {
      value.splice(1, 1);
    } else if (currentArea[0].type === 2 && currentArea[0].code !== item.code) {
      currentArea[0] = item;
      value = currentArea;
    } else {
      value = [];
    }
    dispatch({ type: 'setCategory', payload: [] });
    setShowShadow(value);
    handlePartitionChange(value);
    return true;
  };

  // 当前主分区值
  const currentAreaFilter = useMemo(() => {
    return filterParams.partition;
  }, [filterParams.partition]);
  // 当前分类
  const currentCategoryArray = filterParams.category;

  const categoryNameKey =
    categoryNameKeys[intl.getInitOptions().currentLocale] || 'name_en';
  // console.log(currentCategory);
  // 当前主分区详情
  const currentArea = useMemo(() => {
    const current = filterParams.partition;
    return current;
  }, [filterParams.partition, areaFilters]);
  // 分区及分类组成面包屑
  const breadcrumbItems = useMemo(() => {
    const arr = [
      {
        label: intl.get('suppliers.categary'),
        value: '',
        type: 'resetBread'
      }
    ];
    if (currentArea && currentArea.length) {
      let code = currentArea.map(item => {
        return item.code;
      });
      let value = currentArea.map(item => {
        return item.value;
      });
      code = code.toString();
      value = value.toString();
      arr.push({
        label: value,
        value: code,
        type: 'area'
      });
    }
    if (currentCategoryArray.length) {
      currentCategoryArray.forEach(categoryItem => {
        arr.push({
          label: `"${categoryItem[categoryNameKey]}"`,
          value: categoryItem.code,
          type: 'category'
        });
      });
    }
    return arr;
  }, [currentArea, currentCategoryArray]);

  const filtersDisabled = dataSource === 'similar_by_img';
  // console.log(dataSource)

  // 发货地变更
  const handleShipFromChange = useCallback(
    value => {
      setShipValue(value);

      const newValue = value === 'all' ? '' : value;
      onChange('shipping_from');
      dispatch({ type: 'setShipFrom', payload: newValue });
    },
    [dispatch, onChange]
  );

  // 单选框筛选器变更
  const handleFilterChange = useCallback(
    (e, key) => {
      const value = Number(e.target.checked);
      const changedStoreKey = keyMap[key];
      onChange(changedStoreKey);
      dispatch({ type: `set${key}`, payload: value });
    },
    [dispatch, onChange]
  );

  // 专区变更
  const handlePartitionChange = useCallback(
    value => {
      onChange('partition');
      dispatch({ type: 'setPartition', payload: value });
    },
    [dispatch, onChange]
  );
  // 价格范围变更
  const handlePriceRangeChange = useCallback(
    value => {
      onChange('price', value);
      dispatch({ type: 'setPrice', payload: value });
    },
    [dispatch, onChange]
  );
  // 移除所有面包屑
  const removeAllBread = useCallback(() => {
    setShowShadow('');
    if (!filterParams.partition && !filterParams.category) {
      return false;
    }
    onChange('remove_bread');
    dispatch({ type: 'setPartition', payload: '' });
    dispatch({ type: 'setCategory', payload: [] });
    return true;
  }, [dispatch, onChange, filterParams.partition, filterParams.category]);
  // 移除分类
  const removeCategory = useCallback(
    (categoryItem, removeAllCategory) => {
      if (!filterParams.category.length) {
        return false;
      }

      let newCategories = [];
      if (!removeAllCategory) {
        const index = filterParams.category.findIndex(
          item => item.code === categoryItem.value
        );
        if (index === -1) {
          return false;
        }
        newCategories = filterParams.category.slice(0, index || 1);
      }

      onChange('remove_category');
      dispatch({
        type: 'setCategory',
        payload: newCategories
      });
      return true;
    },
    [dispatch, onChange, filterParams.category]
  );

  // 点击面包屑
  const handleBreadAction = useCallback(
    item => {
      if (item.type === 'resetBread') {
        removeAllBread();
        return;
      }
      setShowShadow(item.value);
      removeCategory(item, item.type === 'area');
    },
    [removeCategory, removeAllBread]
  );
  // 排序控件变化
  const handleSorterChange = useCallback(
    value => {
      // const newValue = value === 'default' ? '' : value;
      onChange('sort');
      dispatch({ type: 'setSorter', payload: value });
    },
    [dispatch, onChange]
  );

  return (
    <div className={styles.filterAndSorter}>
      <div className={styles.whiteBg}>
        <div className={`${styles.common}`}>
          <AreaFilter
            onChange={handlePartitionChange}
            currentArea={currentAreaFilter}
            areas={areaFilters}
            handleClick={handleClick}
            showShadow={showShadow}
            handleClick_second={handleClick_second}
            secondAreaList={secondAreaList}
          />
        </div>
      </div>
      <div
        style={{
          position: 'relative'
        }}
      >
        <div
          id="target"
          style={{
            position: 'absolute',
            top: -118
          }}
        />
      </div>
      <div className={styles.outerContainer}>
        <div className={styles.innerContainer}>
          <div className={`${styles.content} ${styles.filterOuterContianer}`}>
            {/* 价格输入 */}
            {/* <PriceInput
              currentMin={filterParams.min_price}
              currentMax={filterParams.max_price}
              onChange={handlePriceRangeChange}
              disabled={filtersDisabled}
            /> */}
            {/* 发货地 */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i class="material-icons notranslate">public</i>
              <Select
                getPopupContainer={getBaseLayoutPopupContainer}
                dropdownClassName={styles.shippingSelect}
                // dropdownClassName={styles.selectDropdown}
                onChange={handleShipFromChange}
                value={shipValue}
                disabled={filtersDisabled}
                dropdownMatchSelectWidth={false}
                suffixIcon={
                  <img
                    style={{right:'-20px'}}
                    className="clasSelImg"
                    src={require('../../../../assets/newImportList/icon_expand_more-24px.png')}
                  ></img>
                }
                // open
              >
                {shipFromCountries.map(item => {
                  return (
                    <Option key={item.code} value={item.code || 'all'}>
                      {item.value}
                    </Option>
                  );
                })}
              </Select>
            </div>

            {/* {checkboxFilters.map(item => {
              return (
                <Checkbox
                  onChange={e => handleFilterChange(e, item.key)}
                  key={item.key}
                  disabled={filtersDisabled}
                >
                  {item.label}
                </Checkbox>
              );
            })} */}
            {/* 排序控件 */}
            <Sorter
              currentSorter={filterParams.sort}
              onChange={handleSorterChange}
            />
          </div>
          {/* <div className={styles.content}>
            <Sorter
              currentSorter={filterParams.sort}
              onChange={handleSorterChange}
            />
          </div> */}
        </div>
      </div>
      <div>
        {showSearchEmptyTip ? <div className={styles.searchNullTip}>
          <img src={icon} className={styles.icon} alt="" />
          <span className={styles.contents}>
            {emptyTipType === 1 
              ? intl.get('bargain.search_no_data_tip')
              : intl.get('bargain.filter_no_data_tip')}
          </span>
        </div>:null}
      </div>
      <div className={styles.content}>
        <Breadcrumb className={styles.breadcrumb} separator={'>'}>
          {breadcrumbItems.map((item, index) => {
            const isLast = index === breadcrumbItems.length - 1;
            const isFirst = index === 0;
            const handleBreadClick = !isLast
              ? () => handleBreadAction(item)
              : null;
            return (
              <Breadcrumb.Item
                className={`${!isLast ? styles.withAction : ''} ${
                  isFirst ? styles.firstLabel : ''
                }`}
                onClick={handleBreadClick}
                key={`${item.type}_${item.value}`}
              >
                {item.label.replace('"', '').replace('"', '')}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </div>
    </div>
  );
}

export default React.memo(FilterAndSorter);
