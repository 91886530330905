import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'dva';
import {  routerRedux } from 'dva/router';
import { Modal, Button } from 'antd'
import styles from './index.less'

const VatModal = ({showVat,closeVatModal,dispatch}) => {

  const goLink = () => {
    localStorage.setItem('clickModal',1)
    closeVatModal()
    dispatch(routerRedux.push('/settings?tabChangeSta=7'))
  }

  return <Modal
            visible={showVat}
            footer={null}
            closable={false}
          >
      <div style={{paddingTop:30}}>
        {intl.get('home.vat_error')}
      </div>
      <div style={{marginTop:10}}>
       {intl.get('home.var_update')}
      </div>
      <div style={{textAlign:'right',marginTop:20,paddingBottom:30}}>
        <Button type="primary" onClick={goLink}>{intl.get('home.go_modify')}</Button>
      </div>
  </Modal>
}

const mapStateToProps = ({ login }) => ({
  loginInfo: login.loginInfo
});

export default connect(mapStateToProps)(VatModal)
